"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERMISSIONS_FOR_ACTIONS = exports.STATE_TRANSITION_MATRIX = exports.EDITABLE_STATE_LIST_DICTIONARY = void 0;
exports.EDITABLE_STATE_LIST_DICTIONARY = {
    admin: ['unanalyzed', 'inputting', 'inputCompleted', 'approved', 'finished'],
    approver: ['inputCompleted', 'approved'],
    analyst: ['unanalyzed', 'inputting', 'inputCompleted'],
    requester: ['approved', 'finished'],
};
exports.STATE_TRANSITION_MATRIX = {
    admin: {
        unanalyzed: exports.EDITABLE_STATE_LIST_DICTIONARY.admin,
        inputting: exports.EDITABLE_STATE_LIST_DICTIONARY.admin,
        inputCompleted: exports.EDITABLE_STATE_LIST_DICTIONARY.admin,
        approved: exports.EDITABLE_STATE_LIST_DICTIONARY.admin,
        finished: exports.EDITABLE_STATE_LIST_DICTIONARY.admin,
    },
    analyst: {
        unanalyzed: exports.EDITABLE_STATE_LIST_DICTIONARY.analyst,
        inputting: exports.EDITABLE_STATE_LIST_DICTIONARY.analyst,
        inputCompleted: exports.EDITABLE_STATE_LIST_DICTIONARY.analyst,
        approved: ['approved'],
        finished: ['finished'],
    },
    approver: {
        inputCompleted: exports.EDITABLE_STATE_LIST_DICTIONARY.approver,
        approved: exports.EDITABLE_STATE_LIST_DICTIONARY.approver,
    },
    requester: {
        approved: exports.EDITABLE_STATE_LIST_DICTIONARY.requester,
        finished: exports.EDITABLE_STATE_LIST_DICTIONARY.requester,
    },
};
exports.PERMISSIONS_FOR_ACTIONS = {
    DELETE_SAMPLE: ['admin', 'approver', 'requester'],
    CHANGE_ACCOUNT_LOGIN_ID: ['admin'],
    CHANGE_ACCOUNT_NAME: ['admin'],
};
