import Cookies from 'js-cookie'
import queryString from 'query-string'

const CSRF_TOKEN_COOKIE = '_csrf_token'
const CSRF_TOKEN_HEADER = 'x-csrf-token'

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

const csvUploadHeaders = {
  'Content-Type': 'text/csv',
}

type ApiType = 'general' | 'csvUpload'

const getHeaders = (apiType: ApiType = 'general') => {
  const csrfToken = Cookies.get(CSRF_TOKEN_COOKIE)

  let headers: Record<string, string> = { ...defaultHeaders }
  if (apiType === 'csvUpload') {
    headers = {
      ...headers,
      ...csvUploadHeaders,
    }
  }

  if (csrfToken) {
    return {
      ...headers,
      [CSRF_TOKEN_HEADER]: csrfToken,
    }
  } else {
    return headers
  }
}

const _fetch = async (url: string, options: RequestInit) => {
  const response = await fetch(url, options)
  const body = await response.text()
  if (!response.ok) {
    throw new Error(body)
  }
  if (body) {
    return JSON.parse(body)
  }
  return null
}

export const get = async <Res, Query = Record<string, unknown>>(path: string, query?: Query): Promise<Res> => {
  let url = path
  if (query) {
    const _query = queryString.stringify(query, { arrayFormat: 'none' })
    url = `${path}?${_query}`
  }
  const headers = {
    ...getHeaders(),
  }
  const options = {
    method: 'GET',
    headers,
  }

  return await _fetch(url, options)
}

export const post = async <Res, Body = Record<string, unknown>>(path: string, body?: Body): Promise<Res> => {
  const headers = {
    ...getHeaders(),
  }
  const options = {
    method: 'POST',
    body: body ? JSON.stringify(body) : undefined,
    headers,
  }

  return await _fetch(path, options)
}

export const put = async <Res, Body = Record<string, unknown>>(path: string, body?: Body): Promise<Res> => {
  const headers = {
    ...getHeaders(),
  }
  const options = {
    method: 'PUT',
    body: body ? JSON.stringify(body) : undefined,
    headers,
  }

  return await _fetch(path, options)
}

export const del = async <Res>(path: string): Promise<Res> => {
  const headers = {
    ...getHeaders(),
  }
  const options = {
    method: 'DELETE',
    headers,
  }

  return await _fetch(path, options)
}
