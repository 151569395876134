import { Card } from '@mui/material'
import { TabbedForm, Title } from 'react-admin'

import { CountryRegionTab } from './CountryRegionTab'
import { ImportRuleTab } from './ImportRuleTab'

// 共通で使うstringなどを定数化
const DEFAULT_TITLE = '基準取り込み設定'
const IMPORT_RULE_TAB_NAME = 'import_rule'

// -- 以下、基準取り込み設定画面全体 -- //
export const CriterionList = () => {
  return (
    <Card sx={{ marginTop: '24px' }}>
      <Title title={DEFAULT_TITLE} />
      <TabbedForm toolbar={false}>
        <TabbedForm.Tab label="国・地域名">
          {/* // TODO: タブにパス属性を付与しても、サイドメニューからこのタブに遷移するようにする。path="region_name" */}
          <CountryRegionTab />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="取り込みルール" path={IMPORT_RULE_TAB_NAME}>
          <ImportRuleTab />
        </TabbedForm.Tab>
      </TabbedForm>
    </Card>
  )
}
