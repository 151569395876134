"use strict";
// Entity用の定数
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENTITY_CONST = void 0;
exports.ENTITY_CONST = {
    // ラベル用の最大文字数
    MAX_LEN_FOR_LABEL: 250,
    // メモ用の最大文字数
    MAX_LEN_FOR_MEMO: 5000,
    // 一般的な実数値の精度
    PRECISION_FOR_NUMERIC: 11,
    // 一般的な実数値の位取り
    SCALE_FOR_NUMERIC: 4,
    // ログインIDの最小文字数
    MIN_LEN_FOR_LOGIN_ID: 4,
    // ログインIDの最大文字数
    MAX_LEN_FOR_LOGIN_ID: 32,
    // アカウント名の最小文字数
    MIN_LEN_FOR_ACCOUNT_NAME: 4,
    // アカウント名の最大文字数
    MAX_LEN_FOR_ACCOUNT_NAME: 32,
    // パスワードの最小文字数
    MIN_LEN_FOR_PASSWORD: 4,
    // パスワードの最大文字数
    MAX_LEN_FOR_PASSWORD: 72,
    // パスワードの文字種正規表現
    CHAR_TYPE_REGEX_FOR_PASSWORD: /^[!-~]+$/,
    // △基準値の最小値
    MIN_VALUE_MEDIUM_THRESHOLD: 0,
    // △基準値の最大値
    MAX_VALUE_MEDIUM_THRESHOLD: 1,
    // △基準値の精度
    PRECISION_MEDIUM_THRESHOLD: 5,
    // 国・地域名
    MAX_LEN_FOR_REGION_NAME: 250,
    // 国・地域名（略称）
    MAX_LEN_FOR_REGION_ABBREVIATION: 250,
};
