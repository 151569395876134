"use strict";
// 一覧用の定数
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIST_CONST = void 0;
exports.LIST_CONST = {
    // 最小ページ番号
    MIN_PAGE_NUM: 1,
    // 最大ページ番号
    MAX_PAGE_NUM: 1000,
};
