"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SampleGetAllQuery = exports.SampleGetAllQueryFilter = void 0;
const class_validator_1 = require("class-validator");
const const_1 = require("../const");
class SampleGetAllQueryFilter {
    //検体状態 (複数項目選択)
    sampleState_in;
    //検体No (部分一致)
    sampleNo_contains;
    //依頼部署 (部分一致)
    requestDepartment_contains;
    //依頼者 (部分一致)
    requester_contains;
    //依頼日 (以降)
    requestedDate_geq;
    //依頼日 (以前)
    requestedDate_leq;
    //区分1 (部分一致)
    divisionOne_contains;
    //区分2 (部分一致)
    divisionTwo_contains;
    //管理 (部分一致)
    management_contains;
    //品名コード (部分一致)
    productCode_contains;
    //品名 (部分一致)
    productName_contains;
    // (検体追加情報) 検体名・ロット・製造日 (部分一致)
    sampleAdditionalInfo_contains;
    //仕向地 (部分一致)
    destination_contains;
    //製造者 (部分一致)
    manufacturer_contains;
    //判定希望日 (以降)
    judgementDesiredDate_geq;
    //判定希望日 (以前)
    judgementDesiredDate_leq;
    //判定予定日 (以降)
    judgementScheduledDate_geq;
    //判定予定日 (以前)
    judgementScheduledDate_leq;
    //備考 (検体) (部分一致)
    sampleRemarks_contains;
    //検査終了日 (以降)
    inspectEndDate_geq;
    //検査終了日 (以前)
    inspectEndDate_leq;
    //備考 (分析結果) (部分一致)
    analysisRemarks_contains;
    // 総評〇 (国・地域名IDを複数項目選択)
    // HACK: クエリでは文字列として渡さざるを得ないので文字列で
    totalEvaluationGood_in;
    // 総評△ (国・地域名IDを複数項目選択)
    // HACK: クエリでは文字列として渡さざるを得ないので文字列で
    totalEvaluationMedium_in;
    // 総評× (国・地域名IDを複数項目選択)
    // HACK: クエリでは文字列として渡さざるを得ないので文字列で
    totalEvaluationBad_in;
}
__decorate([
    (0, class_validator_1.IsIn)(const_1.SampleStateList, {
        each: true,
    }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], SampleGetAllQueryFilter.prototype, "sampleState_in", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "sampleNo_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "requestDepartment_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "requester_contains", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "requestedDate_geq", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "requestedDate_leq", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "divisionOne_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "divisionTwo_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "management_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "productCode_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "productName_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "sampleAdditionalInfo_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "destination_contains", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "manufacturer_contains", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "judgementDesiredDate_geq", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "judgementDesiredDate_leq", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "judgementScheduledDate_geq", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "judgementScheduledDate_leq", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_MEMO),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "sampleRemarks_contains", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "inspectEndDate_geq", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "inspectEndDate_leq", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_MEMO),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQueryFilter.prototype, "analysisRemarks_contains", void 0);
__decorate([
    (0, class_validator_1.IsNumberString)({}, {
        each: true,
    }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], SampleGetAllQueryFilter.prototype, "totalEvaluationGood_in", void 0);
__decorate([
    (0, class_validator_1.IsNumberString)({}, {
        each: true,
    }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], SampleGetAllQueryFilter.prototype, "totalEvaluationMedium_in", void 0);
__decorate([
    (0, class_validator_1.IsNumberString)({}, {
        each: true,
    }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], SampleGetAllQueryFilter.prototype, "totalEvaluationBad_in", void 0);
exports.SampleGetAllQueryFilter = SampleGetAllQueryFilter;
class SampleGetAllQuery extends SampleGetAllQueryFilter {
    _perPage;
    _page;
    _sortField;
    _sortOrder;
}
__decorate([
    (0, class_validator_1.Max)(const_1.LIST_CONST.MAX_PAGE_NUM),
    (0, class_validator_1.Min)(const_1.LIST_CONST.MIN_PAGE_NUM),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SampleGetAllQuery.prototype, "_perPage", void 0);
__decorate([
    (0, class_validator_1.Min)(const_1.LIST_CONST.MIN_PAGE_NUM),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SampleGetAllQuery.prototype, "_page", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQuery.prototype, "_sortField", void 0);
__decorate([
    (0, class_validator_1.IsIn)(const_1.SortOrderList),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SampleGetAllQuery.prototype, "_sortOrder", void 0);
exports.SampleGetAllQuery = SampleGetAllQuery;
