"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionPutResponse = void 0;
const common_1 = require("../common");
class Region {
    id;
    displayOrder;
    isActive;
    regionName;
    abbreviation;
}
class RegionPutResponse extends common_1.ResponseBase {
}
exports.RegionPutResponse = RegionPutResponse;
