"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IgnoreCriterionKeywordGetAllResponse = void 0;
const common_1 = require("../common");
class IgnoreCriterionKeyword {
    id;
    keyword;
}
class IgnoreCriterionKeywordGetAllResponse extends common_1.ListResponseBase {
}
exports.IgnoreCriterionKeywordGetAllResponse = IgnoreCriterionKeywordGetAllResponse;
