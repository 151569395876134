import { useMediaQuery, Theme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Layout, LayoutProps, useSidebarState } from 'react-admin'

import { customTheme } from '../layout/themes'
import { useElementSize } from '../utils/useElementSize'

import { CustomAppBar } from './CustomAppBar'

const LEFT_PADDING = '8px'
const RIGHT_PADDING = '16px'

export const CustomLayout = (props: LayoutProps) => {
  const { children, ...rest } = props
  const [isOpen] = useSidebarState()
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const sidebarWidth = isLargeEnough ? (isOpen ? customTheme.sidebar.width : customTheme.sidebar.closedWidth) : 0
  const [scrollbarWidth, setScrollbarWidth] = useState(window.innerWidth - document.body.clientWidth)
  const [ref, width, height] = useElementSize<HTMLDivElement>()

  useEffect(() => {
    setScrollbarWidth(window.innerWidth - document.body.clientWidth)
  }, [window.innerWidth, document.body.clientWidth, width, height])

  return (
    <Layout {...rest} appBar={CustomAppBar}>
      <div
        ref={ref}
        style={{
          maxWidth: `calc(100vw - ${sidebarWidth}px - ${LEFT_PADDING} - ${RIGHT_PADDING} - ${scrollbarWidth}px)`,
          marginBottom: '1em',
        }}
      >
        {children}
      </div>
    </Layout>
  )
}
