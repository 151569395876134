import ErrorIcon from '@mui/icons-material/Error'
import { Grid } from '@mui/material'
import { PERMISSIONS_FOR_ACTIONS, SampleStateList } from 'dto'
import {
  BulkDeleteButton,
  ChipField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  FilterButton,
  List,
  ReferenceArrayField,
  SelectArrayInput,
  SelectColumnsButton,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin'

import { useGetRegionSummary } from '../api/summaryQuery'
import { SAMPLE_STATE_DICTIONARY } from '../const'
import { overflowListDatagridConfigurableStyle } from '../layout/overflowStyle'
import { Sample } from '../types'

import { BulkUpdateWithSampleStatus } from './BulkUpdateWithSampleStatus'
import { SamplesExportCSVButton } from './SamplesExportCSVButton'

const SampleListActions = () => {
  // 検体の登録は、管理者・承認者・依頼者のみ可能
  const { permissions } = usePermissions()
  const isAddable = ['admin', 'approver', 'requester'].includes(permissions)

  return (
    <TopToolbar>
      <SelectColumnsButton />
      {isAddable && <CreateButton label="新規登録" />}
      <FilterButton />
      <SamplesExportCSVButton />
    </TopToolbar>
  )
}

const LocalizedSampleStateField = ({ label }: { label: string; source: string }) => {
  const record = useRecordContext<Sample>()
  if (!record) {
    return null
  }

  const displayRecord = {
    ...record,
    displaySampleState: SAMPLE_STATE_DICTIONARY[record.sampleState],
  }
  return <TextField label={label} record={displayRecord} source="displaySampleState" />
}

// 「参照基準値外」用のフィールド
// 列の編集およびヘッダー部表示のために label が必要
const OutOfRefStandardField = ({ label: _ }: { label: string }) => {
  const record = useRecordContext<Sample>()
  if (!record) {
    return null
  }

  // 状態(sampleState)が「承認済み」または「終了」以外の場合はハイフン表⽰
  const sampleStateToShow = SampleStateList.filter((s) => s === 'approved' || s === 'finished')
  const isShown = sampleStateToShow.includes(record.sampleState)
  if (!isShown) {
    return <span> - </span>
  }

  // 参照基準値外の項目があるかで、表示分岐
  if (record.totalOutOfRefStandardValue) {
    return (
      <Grid alignItems="center" container justifyContent="flex-start" sx={{ color: '#F03848', flexWrap: 'nowrap' }}>
        <ErrorIcon sx={{ marginRight: '4px', height: '20px' }} />
        <span>あり</span>
      </Grid>
    )
  } else {
    return <span>なし</span>
  }
}

// 「総評」用のフィールド
const TotalEvaluationField = ({
  label,
  source,
  reference,
}: {
  label: string
  sortable: boolean
  source: string
  reference: string
}) => {
  const record = useRecordContext<Sample>()
  if (!record) {
    return null
  }

  // 状態(sampleState)が「承認済み」または「終了」以外の場合は空欄表示
  const sampleStateToShow = SampleStateList.filter((s) => s === 'approved' || s === 'finished')
  const isShown = sampleStateToShow.includes(record.sampleState)
  if (!isShown) {
    return null
  }

  return (
    <ReferenceArrayField label={label} reference={reference} sortable={false} source={source}>
      <SingleFieldList linkType={false} sx={{ flexWrap: 'nowrap' }}>
        <ChipField size="small" source="abbreviation" />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

export const SampleList = () => {
  const defaultOmit = [
    'requester',
    'requestedDate',
    'divisionOne',
    'divisionTwo',
    'management',
    'productCode',
    'destination',
    'judgementDesiredDate',
    'judgementScheduledDate',
    'sampleRemarks',
    'inspectEndDate',
    'analysisRemarks',
    'updatedAccount.name',
    'updatedTimestamp',
  ]

  // filtering入力欄 設定
  const { data, isLoading } = useGetRegionSummary()
  const regions = data?.data
  const { permissions } = usePermissions()
  const canDeleteSample = PERMISSIONS_FOR_ACTIONS.DELETE_SAMPLE.includes(permissions)

  if (isLoading) {
    return null
  }

  const sampleFilters = [
    <SelectArrayInput
      alwaysOn
      choices={Object.entries(SAMPLE_STATE_DICTIONARY).map(([key, value]) => ({ id: key, name: value }))}
      key="sampleState"
      label="状態"
      source="sampleState_in"
      sx={{ width: '310px' }}
    />,
    <TextInput key="sampleNo" label="検体No." source="sampleNo_contains" />,
    <TextInput key="requestDepartment" label="依頼部署" source="requestDepartment_contains" />,
    <TextInput key="requester" label="依頼者" source="requester_contains" />,
    <DateInput key="requestedDate_leq" label="依頼日 (以前)" source="requestedDate_leq" />,
    <DateInput key="requestedDate_geq" label="依頼日 (以降)" source="requestedDate_geq" />,
    <TextInput key="divisionOne" label="区分1" source="divisionOne_contains" />,
    <TextInput key="divisionTwo" label="区分2" source="divisionTwo_contains" />,
    <TextInput key="management" label="管理" source="management_contains" />,
    <TextInput key="productCode" label="品名コード" source="productCode_contains" />,
    <TextInput key="productName" label="品名" source="productName_contains" />,
    <TextInput key="sampleAdditionalInfo" label="検体名・ロット・製造⽇" source="sampleAdditionalInfo_contains" />,
    <TextInput key="destination" label="仕向地" source="destination_contains" />,
    <TextInput key="manufacturer" label="製造者" source="manufacturer_contains" />,
    <DateInput key="judgementDesiredDate_leq" label="判定希望日 (以前)" source="judgementDesiredDate_leq" />,
    <DateInput key="judgementDesiredDate_geq" label="判定希望日 (以降)" source="judgementDesiredDate_geq" />,
    <DateInput key="judgementScheduledDate_leq" label="判定予定日 (以前)" source="judgementScheduledDate_leq" />,
    <DateInput key="judgementScheduledDate_geq" label="判定予定日 (以降)" source="judgementScheduledDate_geq" />,
    <TextInput key="sampleRemarks" label="備考(検体)" source="sampleRemarks_contains" />,
    <DateInput key="inspectEndDate_leq" label="検査終了日 (以前)" source="inspectEndDate_leq" />,
    <DateInput key="inspectEndDate_geq" label="検査終了日 (以降)" source="inspectEndDate_geq" />,
    <TextInput key="analysisRemarks" label="備考(分析結果)" source="analysisRemarks_contains" />,
    <SelectArrayInput
      choices={regions}
      key="totalEvaluationGood"
      label="総評〇"
      optionText="regionName"
      source="totalEvaluationGood_in"
    />,
    <SelectArrayInput
      // (仕様書より) 総評△については⽇本以外該当しないため
      choices={[regions?.find((r) => r.regionName === '日本')]}
      key="totalEvaluationMedium"
      label="総評△"
      optionText="regionName"
      source="totalEvaluationMedium_in"
    />,
    <SelectArrayInput
      choices={regions}
      key="totalEvaluationBad"
      label="総評✕"
      optionText="regionName"
      source="totalEvaluationBad_in"
    />,
  ]

  const BulkActionButtons = ({ canDeleteSample }: { canDeleteSample: boolean }) => {
    return (
      <>
        <BulkUpdateWithSampleStatus />
        <SamplesExportCSVButton />
        {canDeleteSample && (
          <BulkDeleteButton
            confirmContent="選択した項目を削除しますか？"
            confirmTitle="選択した項目を削除"
            mutationMode="pessimistic"
          />
        )}
      </>
    )
  }

  return (
    <List
      actions={<SampleListActions />}
      filterDefaultValues={{
        sampleState_in: ['unanalyzed', 'inputting', 'inputCompleted', 'approved'],
      }}
      filters={sampleFilters}
      sort={{ field: 'updatedTimestamp', order: 'DESC' }}
      sx={{ ...overflowListDatagridConfigurableStyle }}
    >
      <DatagridConfigurable
        bulkActionButtons={<BulkActionButtons canDeleteSample={canDeleteSample} />}
        omit={defaultOmit}
        rowClick="show"
      >
        <TextField label="検体No." source="sampleNo" />
        <LocalizedSampleStateField label="状態" source="sampleState" />
        <TextField label="依頼部署" source="requestDepartment" />
        <TextField label="依頼者" source="requester" />
        <DateField label="依頼日" source="requestedDate" />
        <TextField label="区分1" source="divisionOne" />
        <TextField label="区分2" source="divisionTwo" />
        <TextField label="管理" source="management" />
        <TextField label="品名コード" source="productCode" />
        <TextField label="品名" source="productName" />
        <TextField label="検体名・ロット・製造日" source="sampleAdditionalInfo" />
        <TextField label="仕向地" source="destination" />
        <TextField label="製造者" source="manufacturer" />
        <DateField label="判定希望日" source="judgementDesiredDate" />
        <DateField label="判定予定日" source="judgementScheduledDate" />
        <TextField label="備考(検体)" source="sampleRemarks" />
        <DateField label="検査終了日" source="inspectEndDate" />
        <TextField label="備考(分析結果)" source="analysisRemarks" />
        {/* 参照基準値外 */}
        <OutOfRefStandardField label="参照基準値外" />
        <TotalEvaluationField
          label="総評〇"
          reference="regions"
          sortable={false}
          source="totalEvaluationGoodRegionIds"
        />
        <TotalEvaluationField
          label="総評△"
          reference="regions"
          sortable={false}
          source="totalEvaluationMediumRegionIds"
        />
        <TotalEvaluationField label="総評✕" reference="regions" sortable={false} source="totalEvaluationBadRegionIds" />
        <TextField label="最終更新者" source="updatedAccount.name" />
        <DateField label="最終更新日時" showTime={true} source="updatedTimestamp" />
      </DatagridConfigurable>
    </List>
  )
}
