"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IgnoreCriterionKeywordDeleteResponse = void 0;
const common_1 = require("../common");
class IgnoreCriterionKeyword {
    id;
    keyword;
}
class IgnoreCriterionKeywordDeleteResponse extends common_1.ResponseBase {
}
exports.IgnoreCriterionKeywordDeleteResponse = IgnoreCriterionKeywordDeleteResponse;
