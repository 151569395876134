"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JudgementCriterionPutResponse = void 0;
const common_1 = require("../common");
class Region {
    id;
    displayOrder;
    isActive;
    regionName;
    abbreviation;
}
class Account {
    id;
    loginId;
    name;
    role;
    state;
}
class JudgementCriterion {
    id;
    name;
    isDefaultApplied;
    mediumThreshold;
    mediumAppliedRegions;
    mediumAppliedRegionIds; //HACK: React-Adminで扱いやすくするためID列を返す
    undetectableThreshold;
    undetectableNotAppliedRegions;
    undetectableNotAppliedRegionIds; //HACK: React-Adminで扱いやすくするためID列を返す
    updatedTimestamp;
    updatedAccount;
}
class JudgementCriterionPutResponse extends common_1.ResponseBase {
}
exports.JudgementCriterionPutResponse = JudgementCriterionPutResponse;
