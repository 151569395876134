interface ErrorObjectJSONWrapper {
  message: string // ErrorObjectをJSON文字列化したものが格納される
}
interface ErrorObject {
  name: string
  message: string
  httpCode: number
}

export const isErrorObjectJSONWrapper = (value: unknown): value is ErrorObjectJSONWrapper => {
  // 値がオブジェクトであるかの判定
  if (typeof value !== 'object' || value === null) {
    return false
  }

  const { message } = value as Record<keyof ErrorObjectJSONWrapper, unknown>
  // messageプロパティが文字列型かを判定
  if (typeof message !== 'string') {
    return false
  }

  return true
}

export const isErrorObject = (value: unknown): value is ErrorObject => {
  // 値がオブジェクトであるかの判定
  if (typeof value !== 'object' || value === null) {
    return false
  }

  const { name, message, httpCode } = value as Record<keyof ErrorObject, unknown>
  // nameプロパティが文字列型かを判定
  if (typeof name !== 'string') {
    return false
  }
  // messageプロパティが文字列型かを判定
  if (typeof message !== 'string') {
    return false
  }
  // httpCodeプロパティが数値型か判定
  if (typeof httpCode !== 'number') {
    return false
  }
  return true
}
