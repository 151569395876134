import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CreateIcon from '@mui/icons-material/Create'
import ScienceIcon from '@mui/icons-material/Science'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import { Box, Card, Grid, CardActionArea } from '@mui/material'
import { styled } from '@mui/system'
import { Title, useRedirect, useCreatePath } from 'react-admin'

import { useGetSampleStateCount } from '../api/useGetSampleStateCount'

// UIの調整関係
const ICON_SIZE = 48
const ICON_COLOR = '#00A040'

const ContainerGrid = styled(Grid)({
  padding: '13px',
})

const ItemCard = styled(Card)({
  borderRadius: '10px',
  height: '90px',
})

const LabelSpan = styled('span')({
  fontSize: '16px',
  color: '#717171',
  display: 'block',
  textAlign: 'right',
})

const CountSpan = styled('span')({
  fontSize: '24px',
  display: 'block',
  textAlign: 'right',
})

// 検索用クエリ作成 (ダッシュボードからの遷移用)
const createFilteringQueries = (params: { [K: string]: string[] }) => {
  const queries = JSON.stringify(params)
  return new URLSearchParams({ filter: queries }).toString()
}

export const Dashboard = () => {
  // クリックすると当該状態でfilteringした分析・判定結果画面に遷移するための準備
  const redirect = useRedirect()
  const createPath = useCreatePath()

  const { unanalyzedCount, inputCompletedCount, approvedCount, inputtingCount, isLoading } = useGetSampleStateCount()

  // 読み込み中ならnullを返す
  if (isLoading) {
    return null
  }

  return (
    <Box sx={{ marginTop: '24px' }}>
      <Title title="ダッシュボード" />
      <ContainerGrid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <ItemCard>
            <CardActionArea
              onClick={() =>
                redirect(
                  createPath({ resource: 'samples', type: 'list' }) +
                    '?' +
                    createFilteringQueries({ sampleState_in: ['unanalyzed'] })
                )
              }
              sx={{ height: 1 }}
            >
              <Grid alignItems="center" container justifyContent="space-around">
                <Grid item>
                  <ScienceIcon sx={{ fontSize: ICON_SIZE, color: ICON_COLOR }} />
                </Grid>
                <Grid item>
                  <LabelSpan>未分析</LabelSpan>
                  <CountSpan>{unanalyzedCount}件</CountSpan>
                </Grid>
              </Grid>
            </CardActionArea>
          </ItemCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ItemCard>
            <CardActionArea
              onClick={() =>
                redirect(
                  createPath({ resource: 'samples', type: 'list' }) +
                    '?' +
                    createFilteringQueries({ sampleState_in: ['inputting'] })
                )
              }
              sx={{ height: 1 }}
            >
              <Grid alignItems="center" container justifyContent="space-around">
                <Grid item>
                  <CreateIcon sx={{ fontSize: ICON_SIZE, color: ICON_COLOR }} />
                </Grid>
                <Grid item>
                  <LabelSpan>入力中</LabelSpan>
                  <CountSpan>{inputtingCount}件</CountSpan>
                </Grid>
              </Grid>
            </CardActionArea>
          </ItemCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ItemCard>
            <CardActionArea
              onClick={() =>
                redirect(
                  createPath({ resource: 'samples', type: 'list' }) +
                    '?' +
                    createFilteringQueries({ sampleState_in: ['inputCompleted'] })
                )
              }
              sx={{ height: 1 }}
            >
              <Grid alignItems="center" container justifyContent="space-around">
                <Grid item>
                  <CheckCircleIcon sx={{ fontSize: ICON_SIZE, color: ICON_COLOR }} />
                </Grid>
                <Grid item>
                  <LabelSpan>入力完了</LabelSpan>
                  <CountSpan>{inputCompletedCount}件</CountSpan>
                </Grid>
              </Grid>
            </CardActionArea>
          </ItemCard>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <ItemCard>
            <CardActionArea
              onClick={() =>
                redirect(
                  createPath({ resource: 'samples', type: 'list' }) +
                    '?' +
                    createFilteringQueries({ sampleState_in: ['approved'] })
                )
              }
              sx={{ height: 1 }}
            >
              <Grid alignItems="center" container justifyContent="space-around">
                <Grid item>
                  <ThumbUpAltIcon sx={{ fontSize: ICON_SIZE, color: ICON_COLOR }} />
                </Grid>
                <Grid item>
                  <LabelSpan>承認済み</LabelSpan>
                  <CountSpan>{approvedCount}件</CountSpan>
                </Grid>
              </Grid>
            </CardActionArea>
          </ItemCard>
        </Grid>
      </ContainerGrid>
    </Box>
  )
}
