import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  useRecordContext,
  usePermissions,
} from 'react-admin'

import { ROLE_DICTIONARY } from '../const'
import { Account } from '../types'

const DEFAULT_TITLE = 'アカウント管理'

// -- 以下、一覧画面 -- //
// 一覧表示において、状態(state)を日本語で表示するためのfield
const LocalizedStateField = ({ source }: { source: string; label: string; sortable: false }) => {
  const record = useRecordContext()
  if (!record) {
    return null
  }

  const contents = record[source] === 'active' ? '有効' : '無効'
  return <span>{contents}</span>
}

// 一覧表示において、権限(role)を日本語で表示するためのfield
const LocalizedRoleField = ({ source }: { source: 'role'; label: '権限'; sortable: false }) => {
  const record = useRecordContext<Account>()
  if (!record) {
    return null
  }

  const contents = ROLE_DICTIONARY[record[source]]
  return <span>{contents}</span>
}

// 管理者のときのみ、アカウント追加ボタンを表示 & そのラベル日本語化
const ListActions = () => {
  const { permissions } = usePermissions()
  const isEditable = ['admin'].includes(permissions)

  return <TopToolbar>{isEditable && <CreateButton label="アカウントを追加" />}</TopToolbar>
}

export const AccountList = () => {
  return (
    <List actions={<ListActions />} title={DEFAULT_TITLE}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="ID" sortable={false} source="id" />
        <LocalizedStateField label="状態" sortable={false} source="state" />
        <TextField label="ログインID" sortable={false} source="loginId" />
        <TextField label="アカウント名" sortable={false} source="name" />
        <LocalizedRoleField label="権限" sortable={false} source="role" />
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}
