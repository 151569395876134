import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import RuleIcon from '@mui/icons-material/Rule'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'

import { authProvider } from './api/AuthProvider'
import { dataProvider } from './api/DataProvider'
import {
  Dashboard,
  SampleList,
  SampleCreate,
  SampleDetail,
  AnalysisResultEdit,
  SampleInformationEdit,
  JudgementCriterionList,
  JudgementCriterionDetails,
  JudgementCriterionCreate,
  OverviewJudgementEdit,
  CriterionValueEdit,
  CriterionList,
  AccountList,
  AccountEdit,
  AccountCreate,
  CountryRegionEdit,
  CountryRegionCreate,
  ImportRuleEdit,
  ImportRuleCreate,
} from './components'
import { CustomLayout } from './components/CustomLayout'
import MyLoginPage from './components/MyLoginPage'
import { customMessages } from './const/notifyMessages'
import { customTheme } from './layout/themes'

const i18nProvider = polyglotI18nProvider(() => customMessages, 'ja')

const App = () => (
  <Admin
    authProvider={authProvider}
    dashboard={Dashboard}
    dataProvider={dataProvider}
    disableTelemetry
    i18nProvider={i18nProvider}
    layout={CustomLayout}
    loginPage={MyLoginPage}
    requireAuth
    theme={customTheme}
  >
    <Resource
      create={SampleCreate}
      icon={FormatListBulletedIcon}
      list={SampleList}
      name="samples"
      options={{ label: '分析・判定結果' }}
      show={SampleDetail}
    >
      <Route element={<AnalysisResultEdit />} path=":id/analysis_result" />
      <Route element={<SampleInformationEdit />} path=":id/sample_info" />
    </Resource>
    <Resource
      create={JudgementCriterionCreate}
      edit={OverviewJudgementEdit}
      icon={RuleIcon}
      list={JudgementCriterionList}
      name="judgement_criteria"
      options={{ label: '判定基準管理' }}
      show={JudgementCriterionDetails}
    >
      <Route element={<CriterionValueEdit />} path=":id/criterion_values" />
    </Resource>
    <Resource icon={MenuBookIcon} list={CriterionList} name="criterion" options={{ label: '基準取り込み設定' }} />
    <CustomRoutes>
      <Route element={<CountryRegionEdit />} path="/regions/:id" />
      <Route element={<CountryRegionCreate />} path="/regions/create" />
      <Route element={<ImportRuleEdit />} path="/ignore_criterion_keywords/:id" />
      <Route element={<ImportRuleCreate />} path="/ignore_criterion_keywords/create" />
    </CustomRoutes>
    <Resource
      create={AccountCreate}
      edit={AccountEdit}
      icon={ManageAccountsIcon}
      list={AccountList}
      name="accounts"
      options={{ label: 'アカウント管理' }}
      recordRepresentation={(record) => `${record.name}`}
    />
  </Admin>
)

export default App
