"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SamplePutWithResultsResponse = void 0;
const common_1 = require("../common");
class Account {
    id;
    loginId;
    name;
    role;
    state;
}
class JudgementCriterion {
    id;
    name;
    isDefaultApplied;
    mediumThreshold;
    undetectableThreshold;
    updatedTimestamp;
}
class AnalysisResult {
    id;
    detectedComponentName;
    detectedValuePpm;
}
class SampleWithResults {
    id;
    sampleNo;
    sampleState;
    requestDepartment;
    requester;
    requestedDate;
    divisionOne;
    divisionTwo;
    management;
    productCode;
    productName;
    sampleAdditionalInfo;
    destination;
    manufacturer;
    judgementDesiredDate;
    judgementScheduledDate;
    sampleRemarks;
    processingFactor;
    inspectEndDate;
    analysisRemarks;
    updatedAccount;
    updatedTimestamp;
    judgementCriterion;
    isResultsRegistered;
    // 検査結果リスト
    analysisResults;
}
class SamplePutWithResultsResponse extends common_1.ResponseBase {
}
exports.SamplePutWithResultsResponse = SamplePutWithResultsResponse;
