import ErrorIcon from '@mui/icons-material/Error'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useState } from 'react'
import {
  Create,
  Datagrid,
  FileInput,
  ListContextProvider,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
  useDataProvider,
  useList,
  useNotify,
  useRedirect,
} from 'react-admin'
import { useMutation } from 'react-query'

import { overflowDatagridStyle } from '../layout/overflowStyle'
import { ParsedCsvSampleDataItem, ParsedCsvSampleDataStatusType } from '../types'
import { fileToString } from '../utils'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

const DEFAULT_TITLE = '分析・判定結果'

const INVALID_DATA_STATUS_TEXT_DICTIONARY: Record<ParsedCsvSampleDataStatusType, string> = {
  valid: '', // ダミーの値。使わない
  duplicatedSampleNo: '検体No重複',
  invalidDate: '日付形式不正',
} as const

// -- csvから一括で検体情報登録 関係 -- //
const PWithNoMargin = styled('p')({
  marginTop: '0px',
  marginBottom: '0px',
})

const PPlaceHolder = styled('p')({
  fontSize: '13px',
  color: '#868686',
})

const TypographyCsvUpload = styled(Typography)({
  marginTop: '18px',
  marginBottom: '21px',
})

const FileInputJudgementCreate = styled(FileInput)({
  '& .RaFileInput-dropZone': {
    height: '156px',
    background: '#EBEBEB 0% 0% no-repeat padding-box',
    border: ' 2px dashed #BDBDBD',
    paddingTop: '48px',
  },
})

const StyledCsvExportLink = styled('a')({
  marginTop: '16px',
  marginBottom: '16px',
  color: '#00A040',
})

const FileNameSpan = styled('span')({
  marginLeft: '4px',
  color: '#00A040',
})

const CsvExportLink = ({ text }: { text: string }) => {
  // csvファイルダウンロード準備
  const fileName = 'kentai_template'
  const url = '/api/samples/csv_template'

  return (
    <StyledCsvExportLink download={fileName} href={url}>
      {text}
    </StyledCsvExportLink>
  )
}

const DropZonePlaceHolder = () => {
  return (
    <div>
      <PWithNoMargin>ここにファイルをドロップ</PWithNoMargin>
      <PPlaceHolder>またはクリックしてファイルを選択</PPlaceHolder>
    </div>
  )
}

const CsvUploaderFormBody = ({ onFileInputChange }: { onFileInputChange: (e: File) => void }) => {
  return (
    <>
      <TypographyCsvUpload variant="h5">検体情報の登録</TypographyCsvUpload>
      <PWithNoMargin>一定の形式でCSVファイルを作成し、アップロードすることで検体情報が登録されます。</PWithNoMargin>
      <CsvExportLink text="記入用テンプレートのダウンロード" />
      <FileInputJudgementCreate
        accept="text/csv"
        label=" "
        onChange={onFileInputChange}
        placeholder={<DropZonePlaceHolder />}
        source="attachments"
      />
    </>
  )
}

const CsvValidDataPreviewEmpty = () => {
  return <p>登録可能なデータはありません</p>
}

const CsvValidDataPreview = ({ validParsedCsv }: { validParsedCsv: ParsedCsvSampleDataItem[] }) => {
  const listContext = useList({
    data: validParsedCsv.map((sampleItem) => {
      return {
        id: sampleItem.value.sampleNo,
        sampleItem,
      }
    }),
  })

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false} empty={<CsvValidDataPreviewEmpty />} sx={overflowDatagridStyle}>
        <TextField label="検体No." sortable={false} source="sampleItem.value.sampleNo" />
        <TextField label="依頼部署" sortable={false} source="sampleItem.value.requestDepartment" />
        <TextField label="依頼者" sortable={false} source="sampleItem.value.requester" />
        <TextField label="依頼日" sortable={false} source="sampleItem.value.requestedDate" />
        <TextField label="区分1" sortable={false} source="sampleItem.value.divisionOne" />
        <TextField label="区分2" sortable={false} source="sampleItem.value.divisionTwo" />
        <TextField label="管理" sortable={false} source="sampleItem.value.management" />
        <TextField label="品名コード" sortable={false} source="sampleItem.value.productCode" />
        <TextField label="品名" sortable={false} source="sampleItem.value.productName" />
        <TextField label="検体名・ロット・製造日" sortable={false} source="sampleItem.value.sampleAdditionalInfo" />
        <TextField label="仕向地" sortable={false} source="sampleItem.value.destination" />
        <TextField label="製造者" sortable={false} source="sampleItem.value.manufacturer" />
        <TextField label="判定希望日" sortable={false} source="sampleItem.value.judgementDesiredDate" />
        <TextField label="判定予定日" sortable={false} source="sampleItem.value.judgementScheduledDate" />
        <TextField label="備考" sortable={false} source="sampleItem.value.sampleRemarks" />
      </Datagrid>
    </ListContextProvider>
  )
}

const CsvErrorPreviewTypography = styled(Typography)({
  margin: '8px',
  color: '#F03848',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const CsvErrorPreviewHeaderTextSpan = styled('span')({
  marginLeft: '8px',
})

const CsvInvalidDataPreview = ({ invalidParsedCsv }: { invalidParsedCsv: ParsedCsvSampleDataItem[] }) => {
  if (invalidParsedCsv.length === 0) {
    return null
  }

  const listContext = useList({
    data: invalidParsedCsv.map((sampleItem) => {
      return {
        id: sampleItem.value.sampleNo,
        sampleItem,
        statusText: INVALID_DATA_STATUS_TEXT_DICTIONARY[sampleItem.status],
      }
    }),
  })

  return (
    <>
      <CsvErrorPreviewTypography variant="subtitle2">
        <ErrorIcon />
        <CsvErrorPreviewHeaderTextSpan>以下のデータは登録されません</CsvErrorPreviewHeaderTextSpan>
      </CsvErrorPreviewTypography>
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false} sx={overflowDatagridStyle}>
          <TextField label="" sortable={false} source="statusText" sx={{ color: '#F03848' }} />
          <TextField label="検体No." sortable={false} source="sampleItem.value.sampleNo" />
          <TextField label="依頼部署" sortable={false} source="sampleItem.value.requestDepartment" />
          <TextField label="依頼者" sortable={false} source="sampleItem.value.requester" />
          <TextField label="依頼日" sortable={false} source="sampleItem.value.requestedDate" />
          <TextField label="区分1" sortable={false} source="sampleItem.value.divisionOne" />
          <TextField label="区分2" sortable={false} source="sampleItem.value.divisionTwo" />
          <TextField label="管理" sortable={false} source="sampleItem.value.management" />
          <TextField label="品名コード" sortable={false} source="sampleItem.value.productCode" />
          <TextField label="品名" sortable={false} source="sampleItem.value.productName" />
          <TextField label="検体名・ロット・製造日" sortable={false} source="sampleItem.value.sampleAdditionalInfo" />
          <TextField label="仕向地" sortable={false} source="sampleItem.value.destination" />
          <TextField label="製造者" sortable={false} source="sampleItem.value.manufacturer" />
          <TextField label="判定希望日" sortable={false} source="sampleItem.value.judgementDesiredDate" />
          <TextField label="判定予定日" sortable={false} source="sampleItem.value.judgementScheduledDate" />
          <TextField label="備考" sortable={false} source="sampleItem.value.sampleRemarks" />
        </Datagrid>
      </ListContextProvider>
    </>
  )
}

const CsvPreviewFormBody = ({
  csvFileName,
  parsedCsv,
}: {
  csvFileName: string
  parsedCsv: ParsedCsvSampleDataItem[]
}) => {
  const validParsedCsv = parsedCsv.filter((p) => p.status === 'valid')
  const invalidParsedCsv = parsedCsv.filter((p) => p.status !== 'valid')
  return (
    <>
      <TypographyCsvUpload variant="h5">検体情報の登録</TypographyCsvUpload>
      <PWithNoMargin>
        <span>アップロードされたファイル名 : </span>
        <FileNameSpan>{csvFileName}</FileNameSpan>
      </PWithNoMargin>
      <TypographyCsvUpload variant="h5">登録内容の確認</TypographyCsvUpload>
      <CsvValidDataPreview validParsedCsv={validParsedCsv} />
      <CsvInvalidDataPreview invalidParsedCsv={invalidParsedCsv} />
    </>
  )
}

// 検体情報 登録画面 (csvアップとか)
export const SampleCreate = () => {
  const redirect = useRedirect()
  const notify = useNotify()
  const [csvFileName, setCsvFileName] = useState<string>('')
  const [parsedCsv, setParsedCsv] = useState<ParsedCsvSampleDataItem[] | undefined>(undefined)

  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // csvファイルの中身をpostし、サーバー側で解析されたJSONを取得する準備
  const dataProvider = useDataProvider()
  const { mutate: uploadCsvFileToParser } = useMutation<void, Error, File>(
    async (csvFile) => {
      if (!csvFile) {
        return notify('ファイルの形式が不正です。', { type: 'error' })
      }
      const csv = await fileToString(csvFile)
      const { data } = await dataProvider.parseSampleCsv({ csv })
      setCsvFileName(csvFile.name)
      setParsedCsv(data)
    },
    {
      onError: onError,
    }
  )

  // 保存ボタン押下時の挙動カスタム (解析済みcsvデータを成形して送信)
  const { mutate: onSave } = useMutation<void, Error>(
    async () => {
      const validData = parsedCsv?.filter((s) => s.status === 'valid').map((s) => s.value)

      // requestをDTOに形式を合わせる
      const samples =
        typeof parsedCsv !== 'undefined'
          ? validData?.map((v) => ({
              ...v,
              sampleState: 'unanalyzed',
            }))
          : []

      const requestData = {
        samples,
      }
      await dataProvider.sampleCreateMany(requestData)
    },
    {
      onSuccess: () => {
        notify('ra.notification.created', { type: 'info' })
        redirect('/samples')
      },
      onError: onError,
    }
  )
  return (
    <Create title={DEFAULT_TITLE + ' > 分析サンプル情報の登録'}>
      {typeof parsedCsv === 'undefined' ? (
        // csvファイルアップロード画面
        <SimpleForm toolbar={false}>
          <CsvUploaderFormBody onFileInputChange={(e) => uploadCsvFileToParser(e)} />
        </SimpleForm>
      ) : (
        // 登録内容のプレビュー・確認画面 (csvアップ後の表示される)
        <SimpleForm
          onSubmit={() => onSave()}
          toolbar={
            <Toolbar>
              <SaveButton label="この内容で登録する" />
            </Toolbar>
          }
        >
          <CsvPreviewFormBody csvFileName={csvFileName} parsedCsv={parsedCsv ?? []} />
        </SimpleForm>
      )}
    </Create>
  )
}
