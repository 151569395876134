"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SamplePutWithSampleStateResponse = void 0;
const common_1 = require("../common");
class UpdateCount {
    // このidはreact-adminの仕様で必要なもの(意味はない)
    id;
    updateCount;
}
class SamplePutWithSampleStateResponse extends common_1.ResponseBase {
}
exports.SamplePutWithSampleStateResponse = SamplePutWithSampleStateResponse;
