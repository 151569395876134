import ContentCreate from '@mui/icons-material/Create'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import BigNumber from 'bignumber.js'
import { AccountRole, EDITABLE_STATE_LIST_DICTIONARY, SampleState, SampleStateList } from 'dto'
import { useState } from 'react'
import {
  ChipField,
  Datagrid,
  DateField,
  Form,
  Labeled,
  List,
  NumberField,
  ReferenceArrayField,
  required,
  SelectInput,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  useCreatePath,
  useGetList,
  useGetOne,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin'
import { Link, useParams } from 'react-router-dom'

import { CRITERION_STATE_DICTIONARY, SAMPLE_STATE_DICTIONARY } from '../const'
import { overflowListStyle } from '../layout/overflowStyle'
import { AnalysisResults, CriterionPesticide, JudgementCriterion, Sample } from '../types'
import { FETCH_PARAMS_FOR_THOUSAND_ITEMS } from '../utils/parameters'

const DEFAULT_TITLE = '分析・判定結果'
const NOT_DETECTED = '不'

const GridSample = styled(Grid)({
  marginTop: '17px',
})

const StackSample = styled(Stack)({
  marginTop: '29px',
  marginBottom: '9px',
})

const LocalizedSampleStateField = ({ label }: { label: string; source: string }) => {
  const record = useRecordContext<Sample>()
  if (!record) {
    return null
  }

  const displayRecord = {
    ...record,
    displaySampleState: SAMPLE_STATE_DICTIONARY[record.sampleState],
  }
  return <TextField label={label} record={displayRecord} source="displaySampleState" />
}

// カスタム編集ボタン
// HACK: 遷移先をカスタムするためにカスタムした
const CustomEditButton = ({ redirectPath }: { redirectPath: string }) => {
  const redirect = useRedirect()

  return (
    <Button
      onClick={() => {
        redirect(redirectPath)
      }}
    >
      <ContentCreate sx={{ fontSize: '18px', marginRight: '8px' }} />
      編集
    </Button>
  )
}

// 詳細画面 > 総評表示用コンポーネント
const TotalEvaluations = ({
  reference,
  source,
  label,
  permission,
  sampleState,
}: {
  reference: string
  source: string
  label: string
  permission: string
  sampleState: SampleState
}) => {
  const record = useRecordContext()

  // 権限が「依頼者」の場合、「承認済み」「終了」以外の状態の検体の総評は⼀覧画⾯、詳細画⾯で⾮表⽰とする。
  const sampleStateToShow = SampleStateList.filter((s) => s === 'approved' || s === 'finished')
  const isShown = permission !== 'requester' || sampleStateToShow.includes(sampleState)
  if (!isShown) {
    return <p style={{ marginBottom: '49px' }}>{label}</p>
  }

  // 総評を表示する場合
  return (
    <>
      {record[source]?.length ? <p>{label}</p> : <p style={{ marginBottom: '49px' }}>{label}</p>}
      <ReferenceArrayField reference={reference} source={source}>
        <SingleFieldList linkType={false}>
          <ChipField size="small" source="regionName" />
        </SingleFieldList>
      </ReferenceArrayField>
    </>
  )
}

// 詳細画面 > 参照基準値外 表示field
const DetailOutOfRefStandardField = ({ label: _ }: { label: string }) => {
  const record = useRecordContext<AnalysisResults>()

  if (record.outOfRefStandardValue) {
    return <span>〇</span>
  }
  return null
}

// 基準値表示Field
const CriterionData = ({
  pesticides,
  regionId,
  undetectableThreshold,
  judgementCriterionId,
}: {
  label: string
  pesticides: CriterionPesticide[] | undefined
  regionId: number
  undetectableThreshold: string
  judgementCriterionId: number
}) => {
  const { data: judgementCriterion, isLoading: judgementCriterionLoading } = useGetOne<JudgementCriterion>(
    `judgement_criteria`,
    {
      id: judgementCriterionId,
    }
  )
  const { detectedComponentName, detectedValuePpm, sample } = useRecordContext()

  if (!judgementCriterion || judgementCriterionLoading) {
    return null
  }

  // 現在の行の検出成分名と農薬成分名和名が一致する基準値情報抽出
  const currenPesticides = pesticides?.find((p) => p.pesticidesNameJapanese === detectedComponentName)
  // 基準値と国情報抽出
  const criterionValueWithInfo = currenPesticides?.criterionDataItems.map((c) => ({
    criterionValue: c.criterionValue,
    criterionState: c.criterionState,
    region: c.region,
  }))
  // 現在の列名(国)と一致する基準値抽出
  const targetPesticides = criterionValueWithInfo?.find((c) => c.region.id === regionId)
  if (typeof targetPesticides === 'undefined') {
    return null
  }

  // criterionStateが'valid'のとき、criterionValueが代入される
  const criterionValue = CRITERION_STATE_DICTIONARY[targetPesticides.criterionState] || targetPesticides.criterionValue
  const leftOrRight = targetPesticides.criterionState === 'valid' ? 'right' : 'left'

  const detectedValuePpmBigNumber = new BigNumber(detectedValuePpm)
  const processingFactorBigNumber = new BigNumber(sample.processingFactor)

  const excludedRegionIds = judgementCriterion.undetectableNotAppliedRegionIds

  // 基準値が「不」の場合に⾚⾊表⽰
  if (criterionValue === NOT_DETECTED) {
    return <div style={{ color: 'red' }}>{NOT_DETECTED}</div>
  }

  // 「不検出と同様に扱う閾値」ルール適用国で、基準値が「不検出と同様に扱う閾値」で設定した値未満の場合に赤色表⽰
  if (
    // 適用除外国でないこと
    !excludedRegionIds.some((excludedRegionId) => excludedRegionId === regionId) &&
    Number(criterionValue) < Number(undetectableThreshold)
  ) {
    return <div style={{ color: 'red', textAlign: leftOrRight }}>{criterionValue}</div>
  }

  // 「検出値 * 加工係数 > 基準値」の場合に⾚⾊表⽰
  if (detectedValuePpmBigNumber.multipliedBy(processingFactorBigNumber).toNumber() > Number(criterionValue)) {
    return <div style={{ color: 'red', textAlign: leftOrRight }}>{criterionValue}</div>
  }

  return <div style={{ textAlign: leftOrRight }}>{criterionValue}</div>
}

// 農薬成分、参照基準値外、検出値、基準値のテーブル表示
const AnalysisResultTable = ({ judgementCriterionId }: { judgementCriterionId: number }) => {
  const { id } = useParams()
  const { data: pesticides, isLoading: pesticidesIsLoading } = useGetList<CriterionPesticide>(
    `judgement_criteria/${judgementCriterionId}/pesticides`,
    FETCH_PARAMS_FOR_THOUSAND_ITEMS
  )
  const recordContext = useRecordContext()
  const { data, isLoading } = useGetList<AnalysisResults>(`samples/${id}/results/`)
  if (isLoading || pesticidesIsLoading) {
    return null
  }

  // 国地域の重複無し配列 作成
  const regions = pesticides?.map((d) => d.criterionDataItems.map((c) => c.region)).flat()
  const uniqueRegions = Array.from(new Map(regions?.map((r) => [r.id, r])).values())

  const undetectableThreshold = recordContext.judgementCriterion.undetectableThreshold

  return (
    <List
      actions={false}
      pagination={false}
      sx={{ marginTop: '18px', '& .RaList-content': { boxShadow: 0 }, ...overflowListStyle }}
      title=" "
    >
      <Datagrid
        bulkActionButtons={false}
        data={data}
        hover={false}
        sx={{
          '& .column-detectedComponentName': { width: '20%' },
          '& .column-totalOutOfRefStandardValue': { width: '10%' },
          '& .column-detectedValuePpm': { width: '10%' },
        }}
      >
        <TextField label="農薬の有効成分" sortable={false} source="detectedComponentName" />
        {/* 参照基準値外 */}
        <DetailOutOfRefStandardField label="参照基準値外" />
        <NumberField label="検出値" sortable={false} source="detectedValuePpm" />
        {
          // 基準値表示
          uniqueRegions.map((r) => {
            return (
              <CriterionData
                judgementCriterionId={judgementCriterionId}
                key={r.id}
                label={r.regionName}
                pesticides={pesticides}
                regionId={r.id}
                undetectableThreshold={undetectableThreshold}
              />
            )
          })
        }
      </Datagrid>
    </List>
  )
}

const SampleStateEditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button onClick={onClick}>
      <ContentCreate sx={{ fontSize: '18px', marginRight: '8px' }} />
      編集
    </Button>
  )
}

type StateSelectChangeEvent = {
  target: {
    value: SampleState
    name: string
  }
}

// HACK: React-AdminのDialogは有償版の機能なので、MUIでカスタム実装
const ChangeStateDialog = ({
  editableStateList,
  open,
  handleClose,
}: {
  editableStateList: SampleState[]
  open: boolean
  handleClose: () => void
}) => {
  const record = useRecordContext<Sample>()
  const refresh = useRefresh()
  const { id, sampleState } = record
  const [selectedState, setSelectedState] = useState(record.sampleState)
  const notify = useNotify()
  const [update] = useUpdate()

  const choices = Array.from(new Set([sampleState, ...editableStateList])).map((s) => ({
    id: s,
    name: SAMPLE_STATE_DICTIONARY[s],
  }))

  // HACK: 型推論がうまく働かずエラーになるので型強制
  const onChangeSelect = (e: unknown) => {
    const ev = e as StateSelectChangeEvent
    const newState = ev.target.value
    setSelectedState(newState)
  }

  const updateState = () => {
    update(
      'samples/sample_state',
      {
        id: '',
        data: {
          id: '',
          ids: [id],
          sampleState: selectedState,
        },
      },
      {
        onSuccess: () => {
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' })
          handleClose()
          refresh()
        },
        onError: () => {
          notify('変更できませんでした。', { type: 'error' })
        },
      }
    )
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>状態の変更</DialogTitle>
      <DialogContent>
        <SelectInput
          choices={choices}
          label="状態"
          onChange={onChangeSelect}
          source="sampleState"
          validate={required()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={updateState}>変更する</Button>
      </DialogActions>
    </Dialog>
  )
}

const SampleStateInfo = () => {
  const record = useRecordContext<Sample>()

  const { permissions: currentRole } = usePermissions<AccountRole>()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const editableStateList = EDITABLE_STATE_LIST_DICTIONARY[currentRole]
  const isEditable = editableStateList.includes(record.sampleState)

  const openDialog = () => {
    setDialogOpen(true)
  }
  const closeDialog = () => {
    setDialogOpen(false)
  }
  return (
    <Form>
      <StackSample alignItems="center" direction="row" spacing={2}>
        <Typography variant="h5">状態</Typography>
        {isEditable && <SampleStateEditButton onClick={openDialog} />}
        <ChangeStateDialog editableStateList={editableStateList} handleClose={closeDialog} open={isDialogOpen} />
      </StackSample>
      <LocalizedSampleStateField label="状態" source="sampleState" />
    </Form>
  )
}

const AnalysisResult = () => {
  const { id } = useParams()
  const createPath = useCreatePath()
  const { permissions } = usePermissions()
  const { data: detectedComponents, isLoading } = useGetList<AnalysisResults>(`samples/${id}/results/`)
  const record = useRecordContext<Sample>()
  if (!record || isLoading) {
    return null
  }

  // 検出成分が0件だったときは、結果の表、表示しない
  const isShown = detectedComponents ? detectedComponents.length > 0 : false

  // 分析結果の登録・編集は管理者と分析者のみ可能
  const isEditable = ['admin', 'analyst'].includes(permissions)

  // 分析結果が未登録のときは、分析結果未登録とだけ表示
  if (!record.isResultsRegistered) {
    return (
      <>
        <StackSample alignItems="center" direction="row" spacing={2}>
          <Typography variant="h5">分析・判定結果</Typography>
          {isEditable && <CustomEditButton redirectPath={`/samples/${id}/analysis_result`} />}
        </StackSample>
        <p>分析結果未登録</p>
      </>
    )
  }

  // 分析・判定結果表示
  return (
    <>
      <StackSample alignItems="center" direction="row" spacing={2}>
        <Typography variant="h5">分析・判定結果</Typography>
        {isEditable && <CustomEditButton redirectPath={`/samples/${id}/analysis_result`} />}
      </StackSample>
      <Grid container>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="検査終了日">
            <DateField label="検査終了日" source="inspectEndDate" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="備考">
            <TextField label="備考(分析結果)" source="analysisRemarks" />
          </Labeled>
        </GridSample>
      </Grid>
      <TotalEvaluations
        label="総評〇"
        permission={permissions}
        reference="regions"
        sampleState={record.sampleState}
        source="totalEvaluationGoodRegionIds"
      />
      <TotalEvaluations
        label="総評△"
        permission={permissions}
        reference="regions"
        sampleState={record.sampleState}
        source="totalEvaluationMediumRegionIds"
      />
      <TotalEvaluations
        label="総評✕"
        permission={permissions}
        reference="regions"
        sampleState={record.sampleState}
        source="totalEvaluationBadRegionIds"
      />
      <Grid container>
        <Labeled label="判定基準" sx={{ marginTop: '30px' }}>
          <GreenLink
            to={createPath({ resource: 'judgement_criteria', type: 'show', id: record.judgementCriterion?.id })}
          >
            {record.judgementCriterion?.name}
          </GreenLink>
        </Labeled>
      </Grid>
      {isShown && typeof record.judgementCriterion?.id !== 'undefined' && (
        <AnalysisResultTable judgementCriterionId={record?.judgementCriterion?.id} />
      )}
      <Grid container>
        <Labeled label="加工係数" sx={{ marginTop: '30px' }}>
          <TextField label="加工係数" source="processingFactor" />
        </Labeled>
      </Grid>
    </>
  )
}

const GreenLink = styled(Link)`
  color: #00a040;
`

// 検体情報 表示用コンポーネント
const SampleInformation = ({ permissions, id }: { permissions: AccountRole; id: string | undefined }) => {
  // 検体情報の登録・編集・削除は、「管理者」「承認者」「依頼者」のみ可能
  const EditableRoles = ['admin', 'approver', 'requester']
  const isEditable = EditableRoles.includes(permissions)

  return (
    <>
      <StackSample alignItems="center" direction="row" spacing={2}>
        <Typography variant="h5">検体情報</Typography>
        {isEditable && <CustomEditButton redirectPath={`/samples/${id}/sample_info`} />}
      </StackSample>
      {/* HACK: Showコンポーネント直下じゃないとTextFieldのlabel属性が機能しないため、Labeledで直接指定している */}
      <Grid container>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="検体No.">
            <TextField source="sampleNo" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="依頼部署">
            <TextField source="requestDepartment" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="依頼者">
            <TextField source="requester" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="依頼日">
            <DateField source="requestedDate" />
          </Labeled>
        </GridSample>
      </Grid>
      <Grid container>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="区分1">
            <TextField source="divisionOne" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="区分2">
            <TextField source="divisionTwo" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="管理">
            <TextField source="management" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="品名コード">
            <TextField source="productCode" />
          </Labeled>
        </GridSample>
      </Grid>
      <Grid container>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="品名">
            <TextField source="productName" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="検体名・ロット・製造日">
            <TextField source="sampleAdditionalInfo" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="仕向地">
            <TextField source="destination" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="製造者">
            <TextField source="manufacturer" />
          </Labeled>
        </GridSample>
      </Grid>
      <Grid container>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="判定希望日">
            <DateField source="judgementDesiredDate" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="判定予定日">
            <DateField source="judgementScheduledDate" />
          </Labeled>
        </GridSample>
      </Grid>
      <GridSample container>
        <Labeled label="備考">
          <TextField source="sampleRemarks" />
        </Labeled>
      </GridSample>
      <Grid container>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="最終更新者">
            <TextField source="updatedAccount.name" />
          </Labeled>
        </GridSample>
        <GridSample item md={3} sm={6} xs={12}>
          <Labeled label="最終更新日時">
            <DateField showTime={true} source="updatedTimestamp" />
          </Labeled>
        </GridSample>
      </Grid>
    </>
  )
}

export const SampleDetail = () => {
  const { id } = useParams()

  // ログインユーザーの権限取得
  // 機能によって、権限ごとにできることが違うので、判定は個々のコンポーネントで行う
  const { permissions } = usePermissions()

  return (
    <Show title={DEFAULT_TITLE}>
      <SimpleShowLayout>
        <SampleStateInfo />
        <AnalysisResult />
        <SampleInformation id={id} permissions={permissions} />
      </SimpleShowLayout>
    </Show>
  )
}
