import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import {
  DateField,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Labeled,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  maxLength,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import { useGetRegionSummary } from '../api/summaryQuery'
import { JudgementCriterionPutRequest, OverviewJudgementRecord } from '../types'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

const DEFAULT_TITLE = '判定基準管理'

// HACK: 画面の幅を小さくすると、それに応じて入力欄の横幅が変わるが、その変わり方がSelectInput, TextInput, PasswordInputで異なる。
// これを統一するために、以下ではminWidthとmaxWidthで同一の値を指定している。
const inputWidth = 252
const descriptionMargin = 17

const SelectInputOverviewJudgement = styled(SelectInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const SelectArrayInputOverviewJudgement = styled(SelectArrayInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const TextInputOverviewJudgement = styled(TextInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const NumberInputOverviewJudgement = styled(NumberInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const SpanOverviewJudgement = styled('span')({
  marginLeft: descriptionMargin,
})

const TypographyOverviewJudgement = styled(Typography)({
  marginTop: '29px',
  marginBottom: '9px',
})

const LabeledOverviewJudgement = styled(Labeled)({
  marginTop: '28px',
  marginRight: '181px',
})

const PWithNoMargin = styled('p')({
  marginTop: '0px',
  marginBottom: '0px',
})

const DivOverviewJudgement = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
})

// 概要・判定基準設定の追加・編集時、入力欄の右側に表示する説明文定義
const nameDescription = '他の判定基準と区別できる名前を設定してください。'
const isDefaultAppliedDescription = '「する」の場合、新規に判定結果を登録する際に標準で選択されます。'
const mediumThresholdDescription = '分析値が基準値 × 倍率より大きく基準値以下の場合に判定を△として扱います。'
const mediumAppliedRegionsDescription = '指定した国・地域に上記ルールを適用します。'
const undetectableThresholdDescription = '基準値が指定値未満の場合、「不検出」と同様に判定を行います。'
const undetectableNotAppliedRegionsDescription =
  '指定した国・地域は上記の設定に関わらず判定基準値通りの判定を行います。'

// バリデーションの設定
const validationName = required('名前は空欄にできません')
const validationNameLength = maxLength(250, '名前は250文字以下にしてください')
const validationIsDefaultApplied = required('標準で適用するかは空欄にできません')
const validationMediumThreshold = required('判定を△とする基準は空欄にできません')
const validationUndetectableThreshold = required('不検出と同様に扱う閾値は空欄にできません')

// -- 以下、詳細ページ (一覧の列をクリックしたら遷移) -- //
// 概要・判定設定タブ
export const OverviewJudgementTab = ({ isEditable }: { isEditable: boolean }) => {
  // 基準値などの数値を取得する
  const record = useRecordContext<OverviewJudgementRecord>()
  if (!record) {
    return null
  }

  // 表示するテキスト準備
  const isDefaultAppliedText = record.isDefaultApplied ? '選択されます。' : 'は選択されません。'
  const mediumAppliedRegionsText = record.mediumAppliedRegions.map((r) => r.regionName).join('、') || '無し'
  const undetectableNotAppliedRegionsText =
    record.undetectableNotAppliedRegions.map((x) => x.regionName).join('、') || '無し'

  return (
    <>
      {isEditable && <EditButton sx={{ marginRight: 'auto' }} />}
      <div>
        <TypographyOverviewJudgement variant="h6">名前</TypographyOverviewJudgement>
        <TextField source="name" />
      </div>
      <div>
        <TypographyOverviewJudgement variant="h6">標準で適用</TypographyOverviewJudgement>
        <PWithNoMargin>{`この基準は新規分析結果の登録時に標準で${isDefaultAppliedText}`}</PWithNoMargin>
      </div>
      <div>
        <TypographyOverviewJudgement variant="h6">判定の設定</TypographyOverviewJudgement>
        <PWithNoMargin>{`判定を△とする基準… 基準値の ${record.mediumThreshold} 倍（適用：${mediumAppliedRegionsText}）`}</PWithNoMargin>
        <PWithNoMargin>{`基準値が ${record.undetectableThreshold} ppm 未満の場合は、不検出と同様に扱う（適用除外：${undetectableNotAppliedRegionsText}）`}</PWithNoMargin>
        {/* HACK: TabbedShowLayout.Tab直下じゃないとTextFieldのlabel属性が機能しないため、Labeledで直接指定している */}
        <LabeledOverviewJudgement label="最終更新者">
          <TextField source="updatedAccount.name" />
        </LabeledOverviewJudgement>
        <LabeledOverviewJudgement label="最終更新日時">
          <DateField showTime={true} source="updatedTimestamp" />
        </LabeledOverviewJudgement>
      </div>
    </>
  )
}

// 保存ボタンのラベル、削除ボタンの確認ダイアログをカスタム
// 登録失敗時のエラー通知は<Edit>コンポーネント側で定義(保存、削除共通のため)
// TODO: 削除確認ダイアログの選択肢を「確認」→「削除」にする
const CustomToolbar = ({
  showDelete,
  target,
  baseRedirect,
}: {
  showDelete: boolean
  target?: string
  baseRedirect?: string
}) => {
  const redirect = useRedirect()
  const id = useParams().id

  // 登録成功時の通知準備
  const notify = useNotify()
  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' })
    redirect(`${baseRedirect}/${id}/show`)
  }

  // エラー時の通知準備
  // 登録時
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }
  // 削除時
  const onErrorDelete = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '削除できませんでした。')
    notify(errorMessage, { type: 'error' })
  }
  return (
    <Toolbar>
      <SaveButton label="この内容で保存する" mutationOptions={{ onError, onSuccess }} type="button" />
      {showDelete && (
        <DeleteWithConfirmButton
          confirmContent="よろしいですか？"
          confirmTitle={`${target}を削除します。`}
          mutationOptions={{ onError: onErrorDelete }}
          redirect={baseRedirect}
          sx={{ marginLeft: 'auto' }}
        />
      )}
    </Toolbar>
  )
}

// 概要・判定設定情報の編集用コンポーネント
export const OverviewJudgementEdit = () => {
  // 国地域関連の選択肢作成用、情報取得
  const { data, isLoading } = useGetRegionSummary()
  const regions = data?.data
  if (isLoading) {
    return null
  }

  // 数値型を文字列型に変換してから送信するための関数
  const transform = (submittingData: JudgementCriterionPutRequest) => ({
    ...submittingData,
    mediumThreshold: submittingData.mediumThreshold.toString(),
    undetectableThreshold: submittingData.undetectableThreshold.toString(),
  })

  return (
    <Edit
      actions={false}
      mutationMode={'pessimistic'}
      title={DEFAULT_TITLE + ' > 分析基準の概要・判定基準設定'}
      transform={transform}
    >
      <SimpleForm
        toolbar={<CustomToolbar baseRedirect={'/judgement_criteria'} showDelete={true} target="概要・判定基準設定" />}
      >
        <Typography variant="h5">分析基準の概要・判定基準設定</Typography>
        <DivOverviewJudgement>
          <TextInputOverviewJudgement label="名前" source="name" validate={[validationName, validationNameLength]} />
          <SpanOverviewJudgement>{nameDescription}</SpanOverviewJudgement>
        </DivOverviewJudgement>
        <DivOverviewJudgement>
          <SelectInputOverviewJudgement
            choices={[
              { id: true, name: 'する' },
              { id: false, name: 'しない' },
            ]}
            label="標準で適用"
            source="isDefaultApplied"
            validate={validationIsDefaultApplied}
          />
          <SpanOverviewJudgement>{isDefaultAppliedDescription}</SpanOverviewJudgement>
        </DivOverviewJudgement>
        <div>
          <DivOverviewJudgement>
            <NumberInputOverviewJudgement
              label="判定を△とする基準（倍）"
              source="mediumThreshold"
              validate={validationMediumThreshold}
            />
            <SpanOverviewJudgement>{mediumThresholdDescription}</SpanOverviewJudgement>
          </DivOverviewJudgement>
          <DivOverviewJudgement>
            <SelectArrayInputOverviewJudgement
              choices={regions}
              isLoading={isLoading}
              label="上記ルールを適用する国・地域"
              optionText="regionName"
              source="mediumAppliedRegionIds"
            />
            <SpanOverviewJudgement>{mediumAppliedRegionsDescription}</SpanOverviewJudgement>
          </DivOverviewJudgement>
        </div>
        <div>
          <DivOverviewJudgement>
            <NumberInputOverviewJudgement
              label="不検出と同様に扱う閾値（ppm）"
              source="undetectableThreshold"
              validate={validationUndetectableThreshold}
            />
            <SpanOverviewJudgement>{undetectableThresholdDescription}</SpanOverviewJudgement>
          </DivOverviewJudgement>
          <DivOverviewJudgement>
            <SelectArrayInputOverviewJudgement
              choices={regions}
              isLoading={isLoading}
              label="上記ルールの適用除外"
              optionText="regionName"
              source="undetectableNotAppliedRegionIds"
            />
            <SpanOverviewJudgement>{undetectableNotAppliedRegionsDescription}</SpanOverviewJudgement>
          </DivOverviewJudgement>
        </div>
      </SimpleForm>
    </Edit>
  )
}
