import { RegionSummaryGetAllResponse } from 'dto'
import { useQuery } from 'react-query'

import { get } from './requester'

export function useGetRegionSummary() {
  const { data, isLoading } = useQuery<RegionSummaryGetAllResponse>('region', () =>
    get<RegionSummaryGetAllResponse>('/api/regions')
  )
  return { data, isLoading }
}
