import { AccountRole, CriterionState, SampleState } from 'dto'

// アカウントロールについて、DBに格納されている値(英語)と日本語名(ユーザーに対して表示する用)を対応させるための連想配列
export const ROLE_DICTIONARY: Record<AccountRole, string> = {
  admin: '管理者',
  approver: '承認者',
  analyst: '分析者',
  requester: '依頼者',
} as const

// 検体状態について、DBに格納されている値(英語)と日本語名(ユーザーに対して表示する用)を対応させるための連想配列
export const SAMPLE_STATE_DICTIONARY: Record<SampleState, string> = {
  unanalyzed: '未分析',
  inputting: '入力中',
  inputCompleted: '入力完了',
  approved: '承認済み',
  finished: '終了',
} as const

// 基準値について、criterionStateの値と表示内容を対応させるための連想配列
export const CRITERION_STATE_DICTIONARY: Record<CriterionState, string> = {
  valid: '', // 形式上用意するが実際は使わず、値を表示する
  notDetected: '不',
  noThreshold: '無',
} as const
