import { SampleGetCountQuery, SampleGetCountResponse } from 'dto'
import { useQuery } from 'react-query'

import { get } from './requester'

type UseGetSampleStateCountType = {
  unanalyzedCount: number | undefined
  inputtingCount: number | undefined
  inputCompletedCount: number | undefined
  approvedCount: number | undefined
  isLoading: boolean
}

export function useGetSampleStateCount(): UseGetSampleStateCountType {
  // 未分析の件数取得
  const { data: unanalyzedCount, isLoading: unanalyzedLoading } = useQuery<SampleGetCountResponse, SampleGetCountQuery>(
    'unanalyzed',
    () => get<SampleGetCountResponse>('api/samples/count', { sampleState: 'unanalyzed' })
  )

  // 入力中の件数取得
  const { data: inputtingCount, isLoading: inputtingLoading } = useQuery<SampleGetCountResponse, SampleGetCountQuery>(
    'inputting',
    () => get<SampleGetCountResponse>('api/samples/count', { sampleState: 'inputting' })
  )

  // 入力完了の件数取得
  const { data: inputCompletedCount, isLoading: inputCompletedLoading } = useQuery<
    SampleGetCountResponse,
    SampleGetCountQuery
  >('inputCompleted', () => get<SampleGetCountResponse>('api/samples/count', { sampleState: 'inputCompleted' }))

  // 承認済みの件数取得
  const { data: approvedCount, isLoading: approvedLoading } = useQuery<SampleGetCountResponse, SampleGetCountQuery>(
    'approved',
    () => get<SampleGetCountResponse>('api/samples/count', { sampleState: 'approved' })
  )

  return {
    unanalyzedCount: unanalyzedCount?.data.count,
    inputtingCount: inputtingCount?.data.count,
    inputCompletedCount: inputCompletedCount?.data.count,
    approvedCount: approvedCount?.data.count,
    isLoading: unanalyzedLoading || inputtingLoading || inputCompletedLoading || approvedLoading,
  }
}
