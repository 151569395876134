"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JudgementCriterionAllDataCreateRequest = void 0;
const class_validator_1 = require("class-validator");
const const_1 = require("../const");
class JudgementCriterion {
    // 名前
    name;
    // 標準で適用
    isDefaultApplied;
    // 判定を△とする基準
    // 整数部1桁小数部4桁
    mediumThreshold;
    // 上記基準を適用する国
    // HACK: RequestからID列が渡される
    mediumAppliedRegionIds;
    // 不検出と同様に扱う閾値
    undetectableThreshold;
    // 上記基準を適用しない国
    // HACK: RequestからID列が渡される
    undetectableNotAppliedRegionIds;
}
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], JudgementCriterion.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsDecimal)(),
    __metadata("design:type", String)
], JudgementCriterion.prototype, "mediumThreshold", void 0);
__decorate([
    (0, class_validator_1.IsDecimal)()
    // 不検出と同様に扱う閾値
    ,
    __metadata("design:type", String)
], JudgementCriterion.prototype, "undetectableThreshold", void 0);
class JudgementCriterionAllDataCreateRequest {
    //基準成分 with 基準値データ
    pesticideDataItems; // HACK 圧縮して文字列化しているが、内部的にはTextPesticideDataItem[]
    //判断基準
    judgementCriterion;
}
exports.JudgementCriterionAllDataCreateRequest = JudgementCriterionAllDataCreateRequest;
