import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import { ENTITY_CONST } from 'dto'
import {
  Create,
  maxLength,
  minLength,
  PasswordInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
  useNotify,
} from 'react-admin'

import { ROLE_DICTIONARY } from '../const'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

const DEFAULT_TITLE = 'アカウント管理'

// HACK: 画面の幅を小さくすると、それに応じて入力欄の横幅が変わるが、その変わり方がSelectInput, TextInput, PasswordInputで異なる。
// これを統一するために、以下ではminWidthとmaxWidthで同一の値を指定している。
const inputWidth = 176
const descriptionMargin = 17

const SelectInputAccount = styled(SelectInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const TextInputAccount = styled(TextInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const PasswordInputAccount = styled(PasswordInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const SpanAccount = styled('span')({
  marginLeft: descriptionMargin,
})

const DivAccount = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
})

// 入力欄の右側に表示する説明文定義
const stateDescription = '管理者のみ変更でき、無効化するとログインできなくなります。自己アカウントは無効化できません。'
const loginIdDescription = 'ログインに使用するIDです。他のアカウントと異なる必要があります。'
const passwordDescription = '半角英数記号で入力してください。'
const nameDescription = '最終更新者の表示などに使用します。'
const roleDescription = '管理者のみ変更できます。また、自己アカウントの権限は変更できません。'

// バリデーションの設定
const validationState = required('状態は空欄にできません')
const validationLoginId = [
  required('ログインIDは空欄にできません'),
  regex(ENTITY_CONST.CHAR_TYPE_REGEX_FOR_PASSWORD, 'ログインIDは半角英数記号で入力してください'),
  minLength(ENTITY_CONST.MIN_LEN_FOR_LOGIN_ID, 'ログインIDは4文字以上で入力してください'),
  maxLength(ENTITY_CONST.MAX_LEN_FOR_LOGIN_ID, 'ログインIDは32文字以内で入力してください'),
]
const validationPassword = [
  required('パスワード入力は空欄にできません'),
  regex(ENTITY_CONST.CHAR_TYPE_REGEX_FOR_PASSWORD, 'パスワードは半角英数記号で入力してください'),
  minLength(ENTITY_CONST.MIN_LEN_FOR_PASSWORD, 'パスワードは4文字以上で入力してください'),
  maxLength(ENTITY_CONST.MAX_LEN_FOR_PASSWORD, 'パスワードは72文字以内で入力してください'),
]
const validationName = [
  required('アカウント名は空欄にできません'),
  minLength(ENTITY_CONST.MIN_LEN_FOR_ACCOUNT_NAME, 'アカウント名は4文字以上で入力してください'),
  maxLength(ENTITY_CONST.MAX_LEN_FOR_ACCOUNT_NAME, 'アカウント名は32文字以内で入力してください'),
]
const validationRole = required('権限は空欄にできません')

// -- 以下、新規登録画面 -- //
// 削除ボタンをなくし、保存ボタンのラベルをカスタム
const UserEditToolbar = () => (
  <Toolbar>
    <SaveButton label="この内容で保存する" />
  </Toolbar>
)

export const AccountCreate = () => {
  // エラーメッセージ通知準備
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  const redirect = useRedirect()
  const onSuccess = () => redirect('/accounts')

  return (
    <Create mutationOptions={{ onError, onSuccess }} title={DEFAULT_TITLE + ' > アカウントの作成'}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <Typography variant="h5">アカウントの設定</Typography>
        <DivAccount>
          <SelectInputAccount
            choices={[
              { id: 'active', name: '有効' },
              { id: 'inactive', name: '無効' },
            ]}
            label="状態"
            source="state"
            validate={validationState}
          />
          <SpanAccount>{stateDescription}</SpanAccount>
        </DivAccount>
        <DivAccount>
          <TextInputAccount
            autoComplete="new-password"
            label="ログインID"
            source="loginId"
            validate={validationLoginId}
          />
          <SpanAccount>{loginIdDescription}</SpanAccount>
        </DivAccount>
        <DivAccount>
          <PasswordInputAccount
            autoComplete="new-password"
            label="パスワード"
            source="password"
            validate={validationPassword}
          />
          <SpanAccount>{passwordDescription}</SpanAccount>
        </DivAccount>
        <DivAccount>
          <TextInputAccount label="アカウント名" source="name" validate={validationName} />
          <SpanAccount>{nameDescription}</SpanAccount>
        </DivAccount>
        <DivAccount>
          <SelectInputAccount
            choices={Object.entries(ROLE_DICTIONARY).map(([key, value]) => ({ id: key, name: value }))}
            label="権限"
            source="role"
            validate={validationRole}
          />
          <SpanAccount>{roleDescription}</SpanAccount>
        </DivAccount>
      </SimpleForm>
    </Create>
  )
}
