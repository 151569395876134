import { Login } from 'react-admin'

import { MyLoginForm } from './MyLoginForm'

const MyLoginPage = () => (
  <Login backgroundImage={'/login_background.jpg'}>
    <MyLoginForm />
  </Login>
)
export default MyLoginPage
