"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IgnoreCriterionKeywordPostResponse = void 0;
const common_1 = require("../common");
class IgnoreCriterionKeyword {
    id;
    keyword;
}
class IgnoreCriterionKeywordPostResponse extends common_1.ResponseBase {
}
exports.IgnoreCriterionKeywordPostResponse = IgnoreCriterionKeywordPostResponse;
