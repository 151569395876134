"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SamplePutRequest = void 0;
const class_validator_1 = require("class-validator");
const const_1 = require("../const");
class SamplePutRequest {
    id;
    sampleNo;
    sampleState;
    requestDepartment;
    requester;
    requestedDate;
    divisionOne;
    divisionTwo;
    management;
    productCode;
    productName;
    sampleAdditionalInfo;
    destination;
    manufacturer;
    judgementDesiredDate;
    judgementScheduledDate;
    sampleRemarks;
    inspectEndDate;
    analysisRemarks;
    // 判定基準
    judgementCriterion;
    isResultsRegistered;
    forceAllTotalEvaluationBad;
}
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "sampleNo", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "requestDepartment", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "requester", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutRequest.prototype, "requestedDate", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "divisionOne", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "divisionTwo", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "management", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "productCode", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "productName", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "sampleAdditionalInfo", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "destination", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "manufacturer", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutRequest.prototype, "judgementDesiredDate", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutRequest.prototype, "judgementScheduledDate", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_MEMO),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "sampleRemarks", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutRequest.prototype, "inspectEndDate", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_MEMO),
    __metadata("design:type", String)
], SamplePutRequest.prototype, "analysisRemarks", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutRequest.prototype, "judgementCriterion", void 0);
exports.SamplePutRequest = SamplePutRequest;
class JudgementCriterion {
    id;
    name;
    isDefaultApplied;
    mediumThreshold;
    undetectableThreshold;
    updatedTimestamp;
}
