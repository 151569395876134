import { Button, CardContent, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import PropTypes from 'prop-types'
import { Form, required, TextInput, useNotify, useRedirect, useSafeSetState, useTranslate } from 'react-admin' // カスタマイズ元ではra-coreからのインポートも全てreact-adminにしないと動かない機能がある。
import { FieldValues } from 'react-hook-form'

import { authProvider } from '../api/AuthProvider'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

// react-adminのLoginFormをカスタマイズしてある。
export const MyLoginForm = (props: LoginFormProps) => {
  const { redirectTo = '/', className } = props
  const [loading, setLoading] = useSafeSetState(false)
  const translate = useTranslate()
  const redirect = useRedirect()
  const notify = useNotify()

  const submit = (values: FieldValues) => {
    setLoading(true)

    authProvider
      .login(values)
      .then(() => {
        redirect(redirectTo)
        setLoading(false)
      })
      .catch((error: unknown) => {
        setLoading(false)
        const errorMessage = resolveErrorMessage(error, 'ログインできませんでした')
        notify(errorMessage, { type: 'error' })
      })
  }

  return (
    <StyledForm className={className} mode="onChange" noValidate onSubmit={submit}>
      <SystemTitle>残留農薬検査結果判定システム</SystemTitle>
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoComplete="username"
          autoFocus
          fullWidth
          label={'ログインID'}
          source="username"
          validate={required()}
        />
        <TextInput
          autoComplete="current-password"
          fullWidth
          label={translate('ra.auth.password')}
          source="password"
          type="password"
          validate={required()}
        />

        <Button
          className={LoginFormClasses.button}
          color="primary"
          disabled={loading}
          fullWidth
          type="submit"
          variant="contained"
        >
          {loading ? (
            <CircularProgress className={LoginFormClasses.icon} size={19} thickness={3} />
          ) : (
            translate('ra.auth.sign_in')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  )
}

const PREFIX = 'RaLoginForm'

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}))

export interface LoginFormProps {
  redirectTo?: string
  className?: string
}

MyLoginForm.propTypes = {
  redirectTo: PropTypes.string,
}

const SystemTitle = styled('div')`
  display: flex;
  justify-content: center;
`
