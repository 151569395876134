"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionGetAllResponse = void 0;
const common_1 = require("../common");
class Region {
    id;
    displayOrder;
    isActive;
    regionName;
    abbreviation;
}
class RegionGetAllResponse extends common_1.ListResponseBase {
}
exports.RegionGetAllResponse = RegionGetAllResponse;
