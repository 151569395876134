import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Card } from '@mui/material'
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  List,
  TextField,
  TopToolbar,
  usePermissions,
} from 'react-admin'
import { useLocation } from 'react-router-dom'

const DEFAULT_TITLE = '判定基準管理'
const ICON_COLOR = '#00A040'

const ListActions = ({ isEditable }: { isEditable: boolean }) => {
  return <TopToolbar>{isEditable && <CreateButton label="判定基準の追加" />}</TopToolbar>
}

// DBに「判定基準」のデータが1つもないとき
const JudgementCriterionEmpty = ({ isEditable }: { isEditable: boolean }) => (
  <Card sx={{ marginTop: '24px', width: 1 }}>
    <Box m={1} textAlign="center">
      {isEditable ? <CreateButton label="判定基準を追加" /> : <span>判定基準がありません</span>}
    </Box>
  </Card>
)

export const JudgementCriterionList = () => {
  // 管理者または承認者のみ編集・追加・削除が可能
  const { permissions } = usePermissions()
  const isEditable = ['admin', 'approver'].includes(permissions)

  // HACK: 表示されていないときはList非表示にすることで余計な読み込みを防ぐ。これはページネーションを動作させるのに必要。
  const location = useLocation()
  const isJudgementCriteriaTabActive = !/.*\/judgement_criteria\/$/.test(location.pathname)

  return isJudgementCriteriaTabActive ? (
    <List
      actions={<ListActions isEditable={isEditable} />}
      empty={<JudgementCriterionEmpty isEditable={isEditable} />}
      sort={{ field: 'updatedTimestamp', order: 'DESC' }}
      title={DEFAULT_TITLE}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <BooleanField
          FalseIcon={null}
          TrueIcon={CheckCircleIcon}
          label="適用"
          source="isDefaultApplied"
          sx={{ color: ICON_COLOR }}
          valueLabelTrue="デフォルトで適用"
        />
        <TextField label="名前" source="name" />
        <TextField label="最終更新者" source="updatedAccount.name" />
        <DateField label="最終更新日時" showTime={true} source="updatedTimestamp" />
      </Datagrid>
    </List>
  ) : null
}
