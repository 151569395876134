// ListコンポーネントにDatagridコンポーネントがネストしているものに使う
// Listコンポーネントのsxに指定
export const overflowListStyle = {
  whiteSpace: 'nowrap',
  '& .RaList-main': { width: '100%' },
  '& .MuiPaper-root': { overflow: 'auto' },
}

// ListコンポーネントにDatagridConfigurableコンポーネントがネストしているものに使う
// Listコンポーネントのsxに指定
export const overflowListDatagridConfigurableStyle = {
  whiteSpace: 'nowrap',
  '& .RaList-main': { width: '100%' },
  '& .RaDatagrid-tableWrapper': { overflow: 'auto', borderRadius: '10px' },
}

// Datagridコンポーネント単体で利用する場合に使う
// Datagridコンポーネントのsxに指定
export const overflowDatagridStyle = {
  whiteSpace: 'nowrap',
  '&.RaDatagrid-root': { width: '100%' },
  '& .RaDatagrid-tableWrapper': { overflow: 'auto' },
}
