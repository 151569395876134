"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionGetOneResponse = void 0;
const common_1 = require("../common");
class Region {
    id;
    displayOrder;
    isActive;
    regionName;
    abbreviation;
}
class RegionGetOneResponse extends common_1.ResponseBase {
}
exports.RegionGetOneResponse = RegionGetOneResponse;
