import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ENTITY_CONST } from 'dto'
import {
  Create,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  List,
  NumberField,
  required,
  maxLength,
  SimpleForm,
  SelectInput,
  SaveButton,
  TextInput,
  TextField,
  Toolbar,
  TopToolbar,
  useNotify,
  usePermissions,
  useGetList,
  useRecordContext,
} from 'react-admin'
import { useParams, useLocation } from 'react-router-dom'

import { Region } from '../types'
import { FETCH_PARAMS_FOR_THOUSAND_ITEMS } from '../utils/parameters'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

// 共通で使う文字列などを定数化
const DEFAULT_TITLE = '基準取り込み設定'
const BASE_URL = 'criterion'
const COUNTRY_REGION_RESOURCE = 'regions'

// 一覧表示において、状態(isActive)を日本語で表示するためのfield (アカウント管理画面とほぼ同一)
const LocalizedIsActiveField = ({ source }: { source: string; label: string; sortable: false }) => {
  const record = useRecordContext()
  if (!record) {
    return null
  }

  const contents = record[source] ? '有効' : '無効'
  return <span>{contents}</span>
}

// -- 以下、国・地域名タブ -- //
const ListActions = () => {
  return (
    <TopToolbar>
      <CreateButton label="国を追加" />
    </TopToolbar>
  )
}

// DBに「国・地域名」のデータが1つもないとき
const CountryRegionEmpty = ({ isEditable }: { isEditable: boolean }) => (
  <Box m={1} textAlign="center">
    {isEditable ? <CreateButton label="国を追加" /> : <span>国・地域名がありません。</span>}
  </Box>
)

export const CountryRegionTab = () => {
  // 管理者または承認者のみ編集・追加・削除が可能
  const { permissions } = usePermissions()
  const isEditable = ['admin', 'approver'].includes(permissions)

  // HACK: 表示されていないときはList非表示にすることで余計な読み込みを防ぐ。これはページネーションを動作させるのに必要。
  const location = useLocation()
  const isImportRuleTabActive = location.pathname.includes('/criterion/import_rule')
  const isRegionNameTabActive = location.pathname.includes('/criterion') && !isImportRuleTabActive

  return (
    <>
      {isRegionNameTabActive ? (
        // HACK: タイトルにresource名を表示しないようにするために、titleを半角スペースにしている。""だとresource名が表示される。falseだとエラー
        <List
          actions={isEditable && <ListActions />}
          empty={<CountryRegionEmpty isEditable={isEditable} />}
          resource={COUNTRY_REGION_RESOURCE}
          sx={{ width: 1 }}
          title=" "
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              '& .column-displayOrder': { width: '10%' },
            }}
          >
            <NumberField label="表示順序" sortable={false} source="displayOrder" />
            <LocalizedIsActiveField label="状態" sortable={false} source="isActive" />
            <TextField label="国・地域名" sortable={false} source="regionName" />
            <TextField label="略称" sortable={false} source="abbreviation" />
            {isEditable && <EditButton label="" />}
          </Datagrid>
        </List>
      ) : null}
    </>
  )
}

// HACK: 画面の幅を小さくすると、それに応じて入力欄の横幅が変わるが、その変わり方がSelectInput, TextInput, PasswordInputで異なる。
// これを統一するために、以下ではminWidthとmaxWidthで同一の値を指定している。
const inputWidth = 176
const descriptionMargin = 17

const SelectInputCountry = styled(SelectInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const TextInputCountry = styled(TextInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const SpanCountry = styled('span')({
  marginLeft: descriptionMargin,
})

const DivCountry = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
})

// 保存ボタンのラベル、削除ボタンの確認ダイアログをカスタム
// TODO: 削除確認ダイアログの選択肢を「確認」→「削除」にする
type customToolbar = { showDelete: boolean; target?: string; redirect?: string }
const CustomToolbar = (props: customToolbar) => {
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '削除できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  return (
    <Toolbar>
      <SaveButton label="この内容で保存する" />
      {props.showDelete && (
        <DeleteWithConfirmButton
          confirmContent="よろしいですか？"
          confirmTitle={`${props.target}を削除します。`}
          mutationOptions={{ onError }}
          redirect={props.redirect}
          sx={{ marginLeft: 'auto' }}
        />
      )}
    </Toolbar>
  )
}

// -- 以下、国・地域名編集画面 -- //
// 入力欄の右側に表示する説明文定義
const displayOrderDescription = '指定した国・地域名の一つ上に挿入します。'
const isActiveDescription = '無効の場合、判定や基準取り込みに使用しません。'
const regionNameDescription = '基準取り込み時の国名等に使用します。'
const abbreviationDescription = '分析・判定結果の一覧画面等での短縮表記に使用します。'

// バリデーションの設定
const validationDisplayOrder = required('表示順序は空欄にできません')
const validationIsActive = required('状態は空欄にできません')
const validationRegionName = [
  required('国・地域名は空欄にできません'),
  maxLength(ENTITY_CONST.MAX_LEN_FOR_REGION_NAME, '国・地域名は250文字以内で入力してください。'),
]
const validationAbbreviation = [
  required('国・地域名は空欄にできません'),
  maxLength(ENTITY_CONST.MAX_LEN_FOR_REGION_ABBREVIATION, '国・地域名（略称）は250文字以内で入力してください。'),
]

export const CountryRegionEdit = () => {
  // エラーメッセージ通知準備
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // 編集に必要な情報取得
  const { id } = useParams()
  const { data, isLoading } = useGetList<Region>(COUNTRY_REGION_RESOURCE, FETCH_PARAMS_FOR_THOUSAND_ITEMS)
  // HACK: isLoadingがtrueの時、早期リターンしないとコンパイルエラーになる(SelectInputのisLoading属性指定してもエラーになる)
  if (isLoading) {
    return null
  }

  // displayOrderの最大値を取得し、「末尾に追加」用選択肢を作成する
  const maxDisplayOrder = data ? data.reduce((a, b) => Math.max(a, b.displayOrder), 0) : void 0
  const endChoiceId = typeof maxDisplayOrder !== 'undefined' ? maxDisplayOrder + 1 : 0

  const displayOrderChoices = data?.map((x) => ({ id: x.displayOrder, name: x.regionName }))
  displayOrderChoices?.push({ id: endChoiceId, name: '末尾に追加' })

  return (
    <Edit
      id={id}
      mutationMode="pessimistic"
      mutationOptions={{ onError }}
      redirect={() => BASE_URL}
      resource={COUNTRY_REGION_RESOURCE}
      title={DEFAULT_TITLE + ' > 国・地域名設定'}
    >
      <SimpleForm toolbar={<CustomToolbar redirect={'/' + BASE_URL} showDelete={true} target="国・地域名" />}>
        <Typography variant="h5">国・地域名の設定</Typography>
        <DivCountry>
          <SelectInputCountry
            choices={displayOrderChoices}
            isLoading={isLoading}
            label="表示順序"
            source="displayOrder"
            validate={validationDisplayOrder}
          />
          <SpanCountry>{displayOrderDescription}</SpanCountry>
        </DivCountry>
        <DivCountry>
          <SelectInputCountry
            choices={[
              { id: true, name: '有効' },
              { id: false, name: '無効' },
            ]}
            label="状態"
            source="isActive"
            validate={validationIsActive}
          />
          <SpanCountry>{isActiveDescription}</SpanCountry>
        </DivCountry>
        <DivCountry>
          <TextInputCountry label="国・地域名" source="regionName" validate={validationRegionName} />
          <SpanCountry>{regionNameDescription}</SpanCountry>
        </DivCountry>
        <DivCountry>
          <TextInputCountry label="国・地域名（略称）" source="abbreviation" validate={validationAbbreviation} />
          <SpanCountry>{abbreviationDescription}</SpanCountry>
        </DivCountry>
      </SimpleForm>
    </Edit>
  )
}

// -- 以下、国・地域名登録画面 -- //
export const CountryRegionCreate = () => {
  // エラーメッセージ通知準備
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // 国地域情報取得
  const { data, isLoading } = useGetList<Region>(COUNTRY_REGION_RESOURCE, FETCH_PARAMS_FOR_THOUSAND_ITEMS)
  // HACK: isLoadingがtrueの時、早期リターンしないとコンパイルエラーになる(SelectInputのisLoading属性指定してもエラーになる)
  if (isLoading) {
    return null
  }

  // displayOrderの最大値を取得し、「末尾に追加」用選択肢を作成する
  const maxDisplayOrder = data ? data.reduce((a, b) => Math.max(a, b.displayOrder), 0) : void 0
  const endChoiceId = typeof maxDisplayOrder !== 'undefined' ? maxDisplayOrder + 1 : 0

  const displayOrderChoices = data?.map((x) => ({ id: x.displayOrder, name: x.regionName }))
  displayOrderChoices?.push({ id: endChoiceId, name: '末尾に追加' })

  return (
    <Create
      mutationOptions={{ onError }}
      redirect={() => BASE_URL}
      resource={COUNTRY_REGION_RESOURCE}
      title={DEFAULT_TITLE + ' > 国・地域名登録'}
    >
      <SimpleForm toolbar={<CustomToolbar showDelete={false} />}>
        <Typography variant="h5">国・地域名の設定</Typography>
        <DivCountry>
          <SelectInputCountry
            choices={displayOrderChoices}
            isLoading={isLoading}
            label="表示順序"
            source="displayOrder"
            validate={validationDisplayOrder}
          />
          <SpanCountry>{displayOrderDescription}</SpanCountry>
        </DivCountry>
        <DivCountry>
          <SelectInputCountry
            choices={[
              { id: true, name: '有効' },
              { id: false, name: '無効' },
            ]}
            defaultValue={true}
            label="状態"
            source="isActive"
            validate={validationIsActive}
          />
          <SpanCountry>{isActiveDescription}</SpanCountry>
        </DivCountry>
        <DivCountry>
          <TextInputCountry label="国・地域名" source="regionName" validate={validationRegionName} />
          <SpanCountry>{regionNameDescription}</SpanCountry>
        </DivCountry>
        <DivCountry>
          <TextInputCountry label="国・地域名（略称）" source="abbreviation" validate={validationAbbreviation} />
          <SpanCountry>{abbreviationDescription}</SpanCountry>
        </DivCountry>
      </SimpleForm>
    </Create>
  )
}
