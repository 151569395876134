import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ENTITY_CONST } from 'dto'
import {
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  maxLength,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useUpdate,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { nullToEmptyTextParser } from '../utils/null_to_empty_text_parser'
import { preventEnterRegist } from '../utils/preventEnterRegist'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'
import { dateValidator } from '../utils/validator'

const DEFAULT_TITLE = '分析・判定結果'

// -- 以下、検体情報 編集画面 関係 -- //
const inputWidth = 304
const TextInputSampleInfo = styled(TextInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const DateInputSampleInfo = styled(DateInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const RemarkInput = styled(TextInput)({
  width: '100%',
})

// バリデーションの設定
const validationSampleNo = [
  required('検体No.は空欄にできません'),
  maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '検体No.は250文字以内で入力してください'),
]
const validateRequestDepartment = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '依頼部署は250文字以内で入力してください')
const validateRequester = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '依頼者は250文字以内で入力してください')
const validateDivisionOne = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '区分1は250文字以内で入力してください')
const validateDivisionTwo = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '区分2は250文字以内で入力してください')
const validateManagement = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '管理は250文字以内で入力してください')
const validateProductCode = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '品名コードは250文字以内で入力してください')
const validateProductName = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '品名は250文字以内で入力してください')
const validateSampleAdditionalInfo = maxLength(
  ENTITY_CONST.MAX_LEN_FOR_LABEL,
  '検体名・ロット・製造日は250文字以内で入力してください'
)
const validateDestination = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '仕向地は250文字以内で入力してください')
const validateManufacturer = maxLength(ENTITY_CONST.MAX_LEN_FOR_LABEL, '製造者は250文字以内で入力してください')
const validateSampleRemarks = maxLength(ENTITY_CONST.MAX_LEN_FOR_MEMO, '備考は5000文字以内で入力してください')

// 保存ボタン押下時の挙動、削除ボタンの確認ダイアログ等をカスタム
// 分析・判定結果画面のみ、保存ボタンのラベルが「登録する」になっている
// TODO: 削除確認ダイアログの選択肢を「確認」→「削除」にする
const CustomToolbar = ({
  showDelete,
  target,
  baseRedirect,
}: {
  showDelete: boolean
  target?: string
  baseRedirect?: string
}) => {
  // 削除時エラー用
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '削除できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // 保存ボタン押下時の挙動カスタム (callするエンドポイント等)
  const [update] = useUpdate()
  const redirect = useRedirect()
  const { getValues, handleSubmit } = useFormContext()

  const save = () => {
    // 必要・不要プロパティ切り分け
    const {
      // 以下、必要なプロパティ
      id,
      ...data
    } = getValues()
    update(
      `samples/${id}/sample_info`,
      { id: '', data: data },
      {
        onSuccess: () => {
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' })
          redirect(`/samples/${id}/show`)
        },
        onError: (error: unknown) => {
          const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
          notify(errorMessage, { type: 'error' })
        },
      }
    )
  }

  return (
    <Toolbar>
      <SaveButton label="この内容で登録する" onClick={handleSubmit(() => save())} />
      {showDelete && (
        <DeleteWithConfirmButton
          confirmContent="よろしいですか？"
          confirmTitle={`${target}を削除します。`}
          mutationOptions={{ onError }}
          redirect={baseRedirect}
          sx={{ marginLeft: 'auto' }}
        />
      )}
    </Toolbar>
  )
}

// 検体情報 編集画面
// TODO: 削除できない
export const SampleInformationEdit = () => {
  const { id } = useParams()
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  return (
    <Edit
      actions={false}
      id={id}
      mutationMode="pessimistic"
      mutationOptions={{ onError }}
      redirect={`/samples/${id}/show`}
      resource={'samples'}
      title={DEFAULT_TITLE + ' > 検体情報の編集'}
    >
      <SimpleForm toolbar={<CustomToolbar baseRedirect={'/samples'} showDelete={true} target="検体情報" />}>
        <Typography variant="h5">検体情報の編集</Typography>
        <TextInputSampleInfo
          label="検体No."
          onKeyDown={preventEnterRegist}
          source="sampleNo"
          validate={validationSampleNo}
        />
        <TextInputSampleInfo
          label="依頼部署"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="requestDepartment"
          validate={validateRequestDepartment}
        />
        <TextInputSampleInfo
          label="依頼者"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="requester"
          validate={validateRequester}
        />
        <DateInputSampleInfo
          label="依頼日"
          onKeyDown={preventEnterRegist}
          source="requestedDate"
          validate={dateValidator}
        />
        <TextInputSampleInfo
          label="区分1"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="divisionOne"
          validate={validateDivisionOne}
        />
        <TextInputSampleInfo
          label="区分2"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="divisionTwo"
          validate={validateDivisionTwo}
        />
        <TextInputSampleInfo
          label="管理"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="management"
          validate={validateManagement}
        />
        <TextInputSampleInfo
          label="品名コード"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="productCode"
          validate={validateProductCode}
        />
        <TextInputSampleInfo
          label="品名"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="productName"
          validate={validateProductName}
        />
        <TextInputSampleInfo
          label="検体名・ロット・製造日"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="sampleAdditionalInfo"
          validate={validateSampleAdditionalInfo}
        />
        <TextInputSampleInfo
          label="仕向地"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="destination"
          validate={validateDestination}
        />
        <TextInputSampleInfo
          label="製造者"
          onKeyDown={preventEnterRegist}
          parse={nullToEmptyTextParser}
          source="manufacturer"
          validate={validateManufacturer}
        />
        <DateInputSampleInfo
          label="判定希望日"
          onKeyDown={preventEnterRegist}
          source="judgementDesiredDate"
          validate={dateValidator}
        />
        <DateInputSampleInfo
          label="判定予定日"
          onKeyDown={preventEnterRegist}
          source="judgementScheduledDate"
          validate={dateValidator}
        />
        <RemarkInput
          label="備考"
          parse={nullToEmptyTextParser}
          source="sampleRemarks"
          validate={validateSampleRemarks}
        />
      </SimpleForm>
    </Edit>
  )
}
