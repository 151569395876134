"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SamplePutWithResultsRequest = void 0;
const class_validator_1 = require("class-validator");
const const_1 = require("../const");
class AnalysisResult {
    id;
    detectedComponentName;
    detectedValuePpm;
}
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], AnalysisResult.prototype, "detectedComponentName", void 0);
__decorate([
    (0, class_validator_1.IsDecimal)(),
    __metadata("design:type", String)
], AnalysisResult.prototype, "detectedValuePpm", void 0);
class SamplePutWithResultsRequest {
    id;
    sampleNo;
    sampleState;
    requestDepartment;
    requester;
    requestedDate;
    divisionOne;
    divisionTwo;
    management;
    productCode;
    productName;
    sampleAdditionalInfo;
    destination;
    manufacturer;
    judgementDesiredDate;
    judgementScheduledDate;
    sampleRemarks;
    processingFactor;
    inspectEndDate;
    analysisRemarks;
    // 判定基準ID
    judgementCriterionId;
    isResultsRegistered;
    forceAllTotalEvaluationBad;
    // 検査結果リスト
    analysisResults;
}
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "sampleNo", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "requestDepartment", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "requester", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutWithResultsRequest.prototype, "requestedDate", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "divisionOne", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "divisionTwo", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "management", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "productCode", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "productName", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "sampleAdditionalInfo", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "destination", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "manufacturer", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutWithResultsRequest.prototype, "judgementDesiredDate", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutWithResultsRequest.prototype, "judgementScheduledDate", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_MEMO),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "sampleRemarks", void 0);
__decorate([
    (0, class_validator_1.IsDecimal)(),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "processingFactor", void 0);
__decorate([
    (0, class_validator_1.IsDateString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutWithResultsRequest.prototype, "inspectEndDate", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_MEMO),
    __metadata("design:type", String)
], SamplePutWithResultsRequest.prototype, "analysisRemarks", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Object)
], SamplePutWithResultsRequest.prototype, "judgementCriterionId", void 0);
exports.SamplePutWithResultsRequest = SamplePutWithResultsRequest;
