export const FETCH_PARAMS_FOR_THOUSAND_ITEMS = {
  pagination: {
    page: 1,
    perPage: 1000,
  },
  sort: {
    order: 'ASC',
    field: 'id',
  },
}
