"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SampleGetOneResponse = void 0;
const common_1 = require("../common");
class Account {
    id;
    loginId;
    name;
    role;
    state;
}
class JudgementCriterion {
    id;
    name;
    isDefaultApplied;
    mediumThreshold;
    undetectableThreshold;
    updatedTimestamp;
}
class Sample {
    id;
    sampleNo;
    sampleState;
    requestDepartment;
    requester;
    requestedDate;
    divisionOne;
    divisionTwo;
    management;
    productCode;
    productName;
    sampleAdditionalInfo;
    destination;
    manufacturer;
    judgementDesiredDate;
    judgementScheduledDate;
    sampleRemarks;
    processingFactor;
    inspectEndDate;
    analysisRemarks;
    updatedAccount;
    updatedTimestamp;
    judgementCriterion;
    isResultsRegistered;
    forceAllTotalEvaluationBad;
    // 以下は判定結果
    // 参照基準値外
    totalOutOfRefStandardValue;
    // 総評〇の地域ID
    totalEvaluationGoodRegionIds;
    // 総評△の地域ID
    totalEvaluationMediumRegionIds;
    // 総評×の地域ID
    totalEvaluationBadRegionIds;
}
class SampleGetOneResponse extends common_1.ResponseBase {
}
exports.SampleGetOneResponse = SampleGetOneResponse;
