"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IgnoreCriterionKeywordGetOneResponse = void 0;
const common_1 = require("../common");
class IgnoreCriterionKeyword {
    id;
    keyword;
}
class IgnoreCriterionKeywordGetOneResponse extends common_1.ResponseBase {
}
exports.IgnoreCriterionKeywordGetOneResponse = IgnoreCriterionKeywordGetOneResponse;
