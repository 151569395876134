import { defaultTheme } from 'react-admin'

const SIDE_BAR_ACTIVE_COLOR = '#0A0' as const

const green = {
  '50': '#00A040',
  '100': '#00A040',
  '200': '#00A040',
  '300': '#00A040',
  '400': '#00A040',
  '500': '#00A040',
  '600': '#00A040',
  '700': '#00A040',
  '800': '#00A040',
  '900': '#00A040',
  A100: '#00A040',
  A200: '#00A040',
  A400: '#00A040',
  A700: '#00A040',
}

export const customTheme = {
  ...defaultTheme,
  palette: {
    primary: green,
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: `3px solid ${SIDE_BAR_ACTIVE_COLOR}`,
            color: SIDE_BAR_ACTIVE_COLOR,
            '& .RaMenuItemLink-icon': {
              color: SIDE_BAR_ACTIVE_COLOR,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
          '&.RaNotification-error': {
            border: 0,
          },
          '.RaNotification-warning': {
            border: 0,
          },
          '.RaNotification-info': {
            border: 0,
          },
          '.RaNotification-success': {
            border: 0,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: '#fff',
        },
      },
      defaultProps: {
        elevation: 1,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': { border: 0 },
        },
      },
    },
  },
}
