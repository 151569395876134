"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionPostRequest = void 0;
const class_validator_1 = require("class-validator");
const const_1 = require("../const");
class RegionPostRequest {
    // 新規作成の場合、指定されたdisplayOrderの位置に新規データが挿入される
    // 既存データのdisplayOrderは挿入と同時に降り直される
    displayOrder;
    isActive;
    regionName;
    abbreviation;
}
__decorate([
    (0, class_validator_1.IsInt)(),
    (0, class_validator_1.Min)(0),
    __metadata("design:type", Number)
], RegionPostRequest.prototype, "displayOrder", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], RegionPostRequest.prototype, "regionName", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    __metadata("design:type", String)
], RegionPostRequest.prototype, "abbreviation", void 0);
exports.RegionPostRequest = RegionPostRequest;
