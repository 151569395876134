import { AppBar } from 'react-admin'

import { CustomUserMenu } from './CustomUserMenu'

export const CustomAppBar = () => {
  return (
    <AppBar
      sx={{
        bgcolor: '#00A040',
        boxShadow: 0,
      }}
      userMenu={<CustomUserMenu />}
    />
  )
}
