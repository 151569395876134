"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
__exportStar(require("./ResultGetAllResponse"), exports);
__exportStar(require("./ResultGetOneResponse"), exports);
__exportStar(require("./SampleDeleteResponse"), exports);
__exportStar(require("./SampleGetAllQuery"), exports);
__exportStar(require("./SampleGetAllResponse"), exports);
__exportStar(require("./SampleGetCountQuery"), exports);
__exportStar(require("./SampleGetCountResponse"), exports);
__exportStar(require("./SampleGetOneResponse"), exports);
__exportStar(require("./SampleParseCsvPostRequest"), exports);
__exportStar(require("./SampleParseCsvPostResponse"), exports);
__exportStar(require("./SamplePostAllRequest"), exports);
__exportStar(require("./SamplePostAllResponse"), exports);
__exportStar(require("./SamplePostExportCSVRequest"), exports);
__exportStar(require("./SamplePostExportCSVResponse"), exports);
__exportStar(require("./SamplePutRequest"), exports);
__exportStar(require("./SamplePutResponse"), exports);
__exportStar(require("./SamplePutWithResultsRequest"), exports);
__exportStar(require("./SamplePutWithResultsResponse"), exports);
__exportStar(require("./SamplePutWithSampleStateRequest"), exports);
__exportStar(require("./SamplePutWithSampleStateResponse"), exports);
