"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountGetMeResponse = void 0;
const common_1 = require("../common");
class Account {
    id;
    loginId;
    name;
    role;
    state;
}
class AccountGetMeResponse extends common_1.ResponseBase {
}
exports.AccountGetMeResponse = AccountGetMeResponse;
