"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PesticideGetAllResponse = void 0;
const common_1 = require("../common");
class Region {
    id;
    displayOrder;
    isActive;
    regionName;
    abbreviation;
}
class CriterionDataItem {
    id;
    region;
    criterionValue;
    criterionState;
}
class CriterionPesticide {
    id;
    pesticidesNameEnglish;
    pesticidesNameJapanese;
    criterionDataItems;
}
class PesticideGetAllResponse extends common_1.ListResponseBase {
}
exports.PesticideGetAllResponse = PesticideGetAllResponse;
