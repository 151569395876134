import { Dispatch, useLayoutEffect, useMemo, useState } from 'react'

type UseElementWidth<Element> = [Dispatch<React.SetStateAction<Element | null>>, number, number]

export const useElementSize = <E extends Element>(): UseElementWidth<E> => {
  const [element, ref] = useState<E | null>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const observer = useMemo(() => {
    return new window.ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect
        setWidth(width)
        setHeight(height)
      }
    })
  }, [])

  useLayoutEffect(() => {
    if (!element) {
      return
    }
    observer.observe(element)
    return () => observer.disconnect()
  }, [element])

  return [ref, width, height]
}
