import { format } from 'date-fns'
import { SamplePostExportCSVRequest } from 'dto'
import { useNotify, useListContext, useCreate, useListFilterContext, ExportButton } from 'react-admin'

import { downloadFile } from '../utils/downloadFile'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

export const SamplesExportCSVButton = () => {
  const notify = useNotify()
  const { selectedIds } = useListContext()
  const [create, { isLoading }] = useCreate()
  const filter = useListFilterContext()

  const onExport = () => {
    const data: SamplePostExportCSVRequest = {
      ids: selectedIds,
      filters: filter.filterValues,
    }
    create(
      'samples/export_csv',
      { data },
      {
        onSuccess: (data: { csv: string }) => {
          const { csv } = data
          downloadFile(csv, composeExportFileName())
        },
        onError: (error: unknown) => {
          const errorMessage = resolveErrorMessage(error, '処理が完了できませんでした。')
          notify(errorMessage, { type: 'error' })
        },
      }
    )
  }

  return <ExportButton disabled={isLoading} exporter={onExport} label="エクスポート" />
}

const composeExportFileName = () => {
  const presentTime = format(new Date(), 'yyyyMMdd_hhmmss')
  return `${presentTime}_kentaijoho.csv`
}
