"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESPONSE_MESSAGE = void 0;
// レスポンスのメッセージ用の定数
exports.RESPONSE_MESSAGE = {
    // 分析・判定結果(検体情報の分析結果)
    // 備考（分析結果）がnull文字
    ANALYSIS_RESULT_ANALYSIS_REMARKS_NO_NULL_CHARACTER: 'analysis_result_analysis_remarks_no_null_character',
    // 加工係数の整数部の桁数が多すぎる
    ANALYSIS_RESULT_NUMERIC_INTEGRAL_PART_TOO_LONG: 'analysis_result_numeric_integral_part_too_long',
    // 加工係数実数の小数部の長さ超過
    ANALYSIS_RESULT_NUMERIC_FRACTIONAL_PART_TOO_LONG: 'analysis_result_numeric_fractional_part_too_long',
    // 実数が正ではない
    ANALYSIS_RESULT_NUMERIC_NOT_POSITIVE: 'analysis_result_numeric_not_positive',
    // 検出農薬成分が長すぎる
    ANALYSIS_RESULT_DETECTED_COMPONENT_NAME_TOO_LONG: 'analysis_result_detected_component_name_too_long',
    // 検出農薬成分がnull文字
    ANALYSIS_RESULT_DETECTED_COMPONENT_NAME_NO_NULL_CHARACTER: 'analysis_result_detected_component_name_no_null_character',
    // 検出農薬の検出値がマイナス
    ANALYSIS_RESULT_DETECTED_COMPONENT_VALUE_NOT_POSITIVE: 'analysis_result_detected_component_value_not_positive',
    // 検出農薬の検出値の整数部が長すぎる
    ANALYSIS_RESULT_DETECTED_COMPONENT_VALUE_INTEGRAL_PART_TOO_LONG: 'analysis_result_detected_component_value_integral_part_too_long',
    // 検出農薬の検出値の小数部が長すぎる
    ANALYSIS_RESULT_DETECTED_COMPONENT_VALUE_FRACTIONAL_PART_TOO_LONG: 'analysis_result_detected_component_value_fractional_part_too_long',
    // 検出農薬成分が重複している
    ANALYSIS_RESULT_DETECTED_COMPONENT_NAME_DUPLICATION: 'analysis_result_detected_component_name_duplication',
    // 分析・判定結果(検体情報)
    // 検体No.の重複
    DUPLICATE_SAMPLE_NO: 'duplicate_sample_no',
    // 検体No.が空
    EMPTY_SAMPLE_NO: 'empty_sample_no',
    // 検体No.にnull文字
    SAMPLE_SAMPLE_NO_NO_NULL_CHARACTER: 'sample_sample_no_no_null_character',
    // 依頼部署にnull文字
    SAMPLE_REQUEST_DEPARTMENT_NO_NULL_CHARACTER: 'sample_request_department_no_null_character',
    // 依頼者にnull文字
    SAMPLE_REQUESTER_NO_NULL_CHARACTER: 'sample_requester_no_null_character',
    // 区分1にnull文字
    SAMPLE_DIVISION_ONE_NO_NULL_CHARACTER: 'sample_division_one_no_null_character',
    // 区分2にnull文字
    SAMPLE_DIVISION_TWO_NO_NULL_CHARACTER: 'sample_division_two_no_null_character',
    // 管理にnull文字
    SAMPLE_MANAGEMENT_NO_NULL_CHARACTER: 'sample_management_no_null_character',
    // 品名コードにnull文字
    SAMPLE_PRODUCT_CODE_NO_NULL_CHARACTER: 'sample_product_code_no_null_character',
    // 品名にnull文字
    SAMPLE_PRODUCT_NAME_NO_NULL_CHARACTER: 'sample_product_name_no_null_character',
    // (検体追加情報) 検体名・ロット・製造日にnull文字
    SAMPLE_SAMPLE_ADDITIONAL_INFO_NO_NULL_CHARACTER: 'sample_sample_additional_info_no_null_character',
    // 仕向地にnull文字
    SAMPLE_DESTINATION_NO_NULL_CHARACTER: 'sample_distination_no_null_character',
    // 製造者にnull文字
    SAMPLE_MANUFACTURER_NO_NULL_CHARACTER: 'sample_manufacturer_no_null_character',
    // 備考 (検体)にnull文字
    SAMPLE_SAMPLE_REMARKS_NO_NULL_CHARACTER: 'sample_sample_remarks_no_null_character',
    // 検体No.が長すぎる
    SAMPLE_CSV_SAMPLE_NO_TOO_LONG: 'sample_csv_sample_no_too_long',
    // 依頼部署が長すぎる
    SAMPLE_CSV_REQUEST_DEPARTMENT_TOO_LONG: 'sample_csv_request_department_too_long',
    // 依頼者が長すぎる
    SAMPLE_CSV_REQUESTER_TOO_LONG: 'sample_csv_requester_too_long',
    // 区分1が長すぎる
    SAMPLE_CSV_DIVISION_ONE_TOO_LONG: 'sample_csv_division_one_too_long',
    // 区分2が長すぎる
    SAMPLE_CSV_DIVISION_TWO_TOO_LONG: 'sample_csv_division_two_too_long',
    // 管理が長すぎる
    SAMPLE_CSV_MANAGEMENT_TOO_LONG: 'sample_csv_management_too_long',
    // 品名コードが長すぎる
    SAMPLE_CSV_PRODUCT_CODE_TOO_LONG: 'sample_csv_product_code_too_long',
    // 品名が長すぎる
    SAMPLE_CSV_PRODUCT_NAME_TOO_LONG: 'sample_csv_product_name_too_long',
    // (検体追加情報) 検体名・ロット・製造日が長すぎる
    SAMPLE_CSV_SAMPLE_ADDITIONAL_INFO_TOO_LONG: 'sample_csv_sample_additional_info_too_long',
    // 仕向地が長すぎる
    SAMPLE_CSV_DESTINATION_TOO_LONG: 'sample_csv_distination_too_long',
    // 製造者が長すぎる
    SAMPLE_CSV_MANUFACTURER_TOO_LONG: 'sample_csv_manufacturer_too_long',
    // 備考 (検体)が長すぎる
    SAMPLE_CSV_SAMPLE_REMARKS_TOO_LONG: 'sample_csv_sample_remarks_too_long',
    // 備考 (分析結果)が長すぎる
    SAMPLE_CSV_ANALYSIS_REMARKS_TOO_LONG: 'sample_csv_analysis_remarks_too_long',
    // 判定基準管理
    // 存在しない判定基準
    NO_JUDGEMENT_CRITERION: 'no_judgement_criterion',
    // 判定基準がすでに検体の判定で使われている
    ALREADY_IN_USE_AT_SAMPLE_JUDGEMENT: 'already_in_use_at_sample_judgement',
    // 判定基準名の不正なnull文字
    CRITERION_NAME_NO_NULL_CHARACTER: 'invalid_null_character',
    // 実数の整数部のの長さ超過
    JUDGEMENT_CRITERION_NUMERIC_INTEGRAL_PART_TOO_LONG: 'judgement_criterion_numeric_integral_part_too_long',
    // 実数の小数部の長さ超過
    JUDGEMENT_CRITERION_NUMERIC_FRACTIONAL_PART_TOO_LONG: 'judgement_criterion_numeric_fractional_part_too_long',
    // 実数が正ではない
    JUDGEMENT_CRITERION_NUMERIC_NOT_POSITIVE: 'judgement_criterion_numeric_not_positive',
    // CSVファイルに「農薬の有効成分」列が含まれていない場合
    NO_PESTICIDES_JP_COLUMN: 'no_pesticides_jp_column',
    // CSVファイルに「農薬の有効成分」の成分名に重複が含まれている場合
    DUPLICATE_PESTICIDES_JP: 'duplicate_pesticides_jp',
    // CSVファイルに「農薬の有効成分」の成分名が空の場合
    EMPTY_PESTICIDES_JP: 'empty_pesticides_jp',
    // CSVファイルの基準値の整数部の長さ超過
    CRITERION_VALUE_NUMERIC_INTEGRAL_PART_TOO_LONG: 'criterion_value_numeric_integral_part_too_long',
    // CSVファイルの基準値の整数部の長さ超過
    CRITERION_VALUE_NUMERIC_FRACTIONAL_PART_TOO_LONG: 'criterion_value_numeric_fractional_part_too_long',
    // 基準取り込み設定(国・地域名)
    // すでに国名が判定基準で使われている
    ALREADY_IN_USE_AT_JUDGEMENT_CRITERIA: 'already_in_use_at_judgement_criteria',
    // 重複する国・地域名
    DUPLICATE_REGION_NAME: 'duplicate_region_name',
    // 国・地域名にnull文字
    REGION_NAME_NO_NULL_CHARACTER: 'region_name_no_null_character',
    // 重複する国・地域名（略称）
    DUPLICATE_REGION_ABBREVIATION: 'duplicate_region_abbreviation',
    // 国・地域名（略称）にnull文字
    REGION_ABBREVIATION_NO_NULL_CHARACTER: 'region_abbreviation_no_null_character',
    // 取り込みルール
    // 基準値なしとして扱う文字列にnull文字
    IGNORE_CRITERION_KEYWORDS_NO_NULL_CHARACTER: 'ignore_criterion_keywords_no_null_character',
    // CSVの読み込み
    // 読み込んだファイルがCSVではない場合
    INVALID_FILE_FORMAT: 'invalid_file_format',
    // CSVの列が不正
    INVALID_CSV_COLUMNS: 'invalid_csv_columns',
    // アカウント
    // アカウントのログインIDの重複
    DUPLICATE_KEY_ACCOUNT_LOGIN_ID: 'duplicate_key_account_login_id',
    // アカウントIDが長すぎる
    ACCOUNT_LOGIN_ID_TOO_LONG: 'account_login_id_too_long',
    // アカウントIDが短すぎる
    ACCOUNT_LOGIN_ID_TOO_SHORT: 'account_login_id_too_short',
    // アカウントIDにnull文字が含まれる
    ACCOUNT_LOGIN_ID_NO_NULL_CHARACTER: 'account_login_id_no_null_character',
    // アカウント名が長すぎる
    ACCOUNT_NAME_TOO_LONG: 'account_name_too_long',
    // アカウント名が短すぎる
    ACCOUNT_NAME_TOO_SHORT: 'account_name_too_short',
    // アカウント名にnull文字が含まれる
    ACCOUNT_NAME_NO_NULL_CHARACTER: 'account_name_no_null_character',
    // ログインフォーム
    // 要素を満たしていない（ログイン認証で主に）
    INVALID_BODY: "Invalid body, check 'errors' property for more info.",
    // ログインIDかパスワードが違う
    INVALID_ID_OR_PASSWORD: 'id_or_password_is_invalid',
    // ユーザーは停止中
    INVALID_STATE: 'invalid_state',
    // アカウントの権限
    // 検体情報を変更する権限がない
    NO_AUTHORITY_FOR_EDIT_SAMPLE: 'no_authority_for_edit_sample',
    // 分析結果を変更する権限がない
    NO_AUTHORITY_FOR_EDIT_ANALYSIS_RESULT: 'no_authority_for_edit_analysis_result',
    // 検体について特定の状態変更できる権限がない
    NO_AUTHORITY_TO_CHANGE_SAMPLE_STATE: 'no_authority_to_change_sample_state',
    // 共通のエラー
    // 有効なアカウントでない
    NOT_VALID_ACCOUNT: 'not_valid_account',
};
