import { isErrorObject, isErrorObjectJSONWrapper } from './error_utils'

// バックエンドからのエラーメッセージ判定
export const resolveErrorMessage = (
  error: unknown,
  defaultErrorMessage:
    | '登録できませんでした。'
    | '削除できませんでした。'
    | '処理が完了できませんでした。'
    | 'ログインできませんでした'
): string => {
  if (isErrorObjectJSONWrapper(error)) {
    // エラーの中身が入っていない場合、早期リターン
    if (!error.message) {
      return defaultErrorMessage
    }

    // エラーメッセージをparseし、内容判定
    const errorContents = JSON.parse(error.message)
    if (isErrorObject(errorContents)) {
      if (['AccessDeniedError', 'TooManyRequestsError', 'AuthorizationRequiredError'].includes(errorContents.name)) {
        return errorContents.name
      }

      return errorContents.message
    }
  }
  return defaultErrorMessage
}
