"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SampleGetCountResponse = void 0;
const common_1 = require("../common");
class CountWrapper {
    // 件数
    count;
}
class SampleGetCountResponse extends common_1.ResponseBase {
}
exports.SampleGetCountResponse = SampleGetCountResponse;
