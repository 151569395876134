import japaneseMessages from '@bicstone/ra-language-japanese'
import { RESPONSE_MESSAGE } from 'dto'

export const customMessages = {
  // 分析・判定結果(検体情報の分析結果)
  // 備考（分析結果）がnull文字
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_ANALYSIS_REMARKS_NO_NULL_CHARACTER]:
    '備考（分析結果）に使用できない文字が含まれています。',
  // 加工係数の整数部の桁数が多すぎる
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_NUMERIC_INTEGRAL_PART_TOO_LONG]: '加工係数の整数部の桁数が多すぎます。',
  // 加工係数実数の小数部の長さ超過
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_NUMERIC_FRACTIONAL_PART_TOO_LONG]: '加工係数の小数部の桁数が多すぎます。',
  // 実数が正ではない
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_NUMERIC_NOT_POSITIVE]: '加工係数に正の値を入力してください。',
  // 検出農薬成分が長すぎる
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_DETECTED_COMPONENT_NAME_TOO_LONG]: '検出農薬成分が長すぎます。',
  // 検出農薬成分がnull文字
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_DETECTED_COMPONENT_NAME_NO_NULL_CHARACTER]:
    '検出農薬成分に使用できない文字が含まれています。',
  // 検出農薬の検出値がマイナス
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_DETECTED_COMPONENT_VALUE_NOT_POSITIVE]:
    '検出農薬成分の検出値に正の値を入力してください。',
  // 検出農薬の検出値の整数部が長すぎる
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_DETECTED_COMPONENT_VALUE_INTEGRAL_PART_TOO_LONG]:
    '検出農薬成分の検出値の整数部が長すぎます。',
  // 検出農薬の検出値の小数部が長すぎる
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_DETECTED_COMPONENT_VALUE_FRACTIONAL_PART_TOO_LONG]:
    '検出農薬成分の検出値の小数部が長すぎます。',
  // 検出農薬成分が重複している
  [RESPONSE_MESSAGE.ANALYSIS_RESULT_DETECTED_COMPONENT_NAME_DUPLICATION]:
    '検出農薬成分が重複しているため、保存できません。',

  // 分析・判定結果(検体情報)
  // 検体No.の重複
  [RESPONSE_MESSAGE.DUPLICATE_SAMPLE_NO]: '検体No.に重複があります。',
  // 検体No.が空
  [RESPONSE_MESSAGE.EMPTY_SAMPLE_NO]: '検体No.が空の行があります。',
  // 検体No.にnull文字
  [RESPONSE_MESSAGE.SAMPLE_SAMPLE_NO_NO_NULL_CHARACTER]: '検体No.に使用できない文字が含まれています',
  // 依頼部署にnull文字
  [RESPONSE_MESSAGE.SAMPLE_REQUEST_DEPARTMENT_NO_NULL_CHARACTER]: '依頼部署に使用できない文字が含まれています',
  // 依頼者にnull文字
  [RESPONSE_MESSAGE.SAMPLE_REQUESTER_NO_NULL_CHARACTER]: '依頼者に使用できない文字が含まれています',
  // 区分1にnull文字
  [RESPONSE_MESSAGE.SAMPLE_DIVISION_ONE_NO_NULL_CHARACTER]: '区分1に使用できない文字が含まれています',
  // 区分2にnull文字
  [RESPONSE_MESSAGE.SAMPLE_DIVISION_TWO_NO_NULL_CHARACTER]: '区分2に使用できない文字が含まれています',
  // 管理にnull文字
  [RESPONSE_MESSAGE.SAMPLE_MANAGEMENT_NO_NULL_CHARACTER]: '管理に使用できない文字が含まれています',
  // 品名コードにnull文字
  [RESPONSE_MESSAGE.SAMPLE_PRODUCT_CODE_NO_NULL_CHARACTER]: '品名コードに使用できない文字が含まれています',
  // 品名にnull文字
  [RESPONSE_MESSAGE.SAMPLE_PRODUCT_NAME_NO_NULL_CHARACTER]: '品名に使用できない文字が含まれています',
  // (検体追加情報) 検体名・ロット・製造日にnull文字
  [RESPONSE_MESSAGE.SAMPLE_SAMPLE_ADDITIONAL_INFO_NO_NULL_CHARACTER]:
    '検体名・ロット・製造日に使用できない文字が含まれています',
  // 仕向地にnull文字
  [RESPONSE_MESSAGE.SAMPLE_DESTINATION_NO_NULL_CHARACTER]: '仕向地に使用できない文字が含まれています',
  // 製造者にnull文字
  [RESPONSE_MESSAGE.SAMPLE_MANUFACTURER_NO_NULL_CHARACTER]: '製造者に使用できない文字が含まれています',
  // 備考 (検体)にnull文字
  [RESPONSE_MESSAGE.SAMPLE_SAMPLE_REMARKS_NO_NULL_CHARACTER]: '備考（検体）に使用できない文字が含まれています',
  // 検体No.が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_SAMPLE_NO_TOO_LONG]: '検体No.が長すぎます。',
  // 依頼部署が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_REQUEST_DEPARTMENT_TOO_LONG]: '依頼部署が長すぎます。',
  // 依頼者が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_REQUESTER_TOO_LONG]: '依頼者が長すぎます。',
  // 区分1が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_DIVISION_ONE_TOO_LONG]: '区分1が長すぎます。',
  // 区分2が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_DIVISION_TWO_TOO_LONG]: '区分2が長すぎます。',
  // 管理が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_MANAGEMENT_TOO_LONG]: '管理が長すぎます。',
  // 品名コードが長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_PRODUCT_CODE_TOO_LONG]: '品名コードが長すぎます。',
  // 品名が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_PRODUCT_NAME_TOO_LONG]: '品名が長すぎます。',
  // (検体追加情報) 検体名・ロット・製造日が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_SAMPLE_ADDITIONAL_INFO_TOO_LONG]: '検体名・ロット・製造日が長すぎます。',
  // 仕向地が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_DESTINATION_TOO_LONG]: '仕向地が長すぎます。',
  // 製造者が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_MANUFACTURER_TOO_LONG]: '製造者が長すぎます。',
  // 備考 (検体)が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_SAMPLE_REMARKS_TOO_LONG]: '備考 (検体)が長すぎます。',
  // 備考 (分析結果)が長すぎる
  [RESPONSE_MESSAGE.SAMPLE_CSV_ANALYSIS_REMARKS_TOO_LONG]: '備考 (分析結果)が長すぎます。',

  // 判定基準管理
  // 存在しない判定基準
  [RESPONSE_MESSAGE.NO_JUDGEMENT_CRITERION]: '判定基準が存在しません。',
  // 判定基準がすでに検体の判定で使われている
  [RESPONSE_MESSAGE.ALREADY_IN_USE_AT_SAMPLE_JUDGEMENT]: '判定に使⽤されている分析基準のため削除できません。',
  // 判定基準名の不正なnull文字
  [RESPONSE_MESSAGE.CRITERION_NAME_NO_NULL_CHARACTER]: '使用できない文字が含まれています。',
  // 実数の整数部のの長さ超過
  [RESPONSE_MESSAGE.JUDGEMENT_CRITERION_NUMERIC_INTEGRAL_PART_TOO_LONG]: '整数部の桁数が多すぎます。',
  // 実数の小数部の長さ超過
  [RESPONSE_MESSAGE.JUDGEMENT_CRITERION_NUMERIC_FRACTIONAL_PART_TOO_LONG]: '小数部の桁数が多すぎます。',
  // 実数が正ではない
  [RESPONSE_MESSAGE.JUDGEMENT_CRITERION_NUMERIC_NOT_POSITIVE]: '正の値を入力してください。',
  // CSVファイルに「農薬の有効成分」列が含まれていない場合
  [RESPONSE_MESSAGE.NO_PESTICIDES_JP_COLUMN]: '「農薬の有効成分」列が含まれていません。',
  // CSVファイルに「農薬の有効成分」の成分名に重複が含まれている場合
  [RESPONSE_MESSAGE.DUPLICATE_PESTICIDES_JP]: '「農薬の有効成分」列に重複があります。',
  // CSVファイルに「農薬の有効成分」の成分名が空の場合
  [RESPONSE_MESSAGE.EMPTY_PESTICIDES_JP]: '「農薬の有効成分」列に空欄があります。',
  // CSVファイルの基準値の整数部の長さ超過
  [RESPONSE_MESSAGE.CRITERION_VALUE_NUMERIC_INTEGRAL_PART_TOO_LONG]: '基準値に不正な値があります。',
  // CSVファイルの基準値の小数部の長さ超過
  [RESPONSE_MESSAGE.CRITERION_VALUE_NUMERIC_FRACTIONAL_PART_TOO_LONG]: '基準値に不正な値があります。',

  // 基準取り込み設定(国・地域名)
  // すでに国名が判定基準で使われている
  [RESPONSE_MESSAGE.ALREADY_IN_USE_AT_JUDGEMENT_CRITERIA]: '判定基準で使⽤されているため、削除できません。',
  // 重複する国・地域名
  [RESPONSE_MESSAGE.DUPLICATE_REGION_NAME]: '国・地域名が重複しているため、保存できません。',
  // 国・地域名にnull文字
  [RESPONSE_MESSAGE.REGION_NAME_NO_NULL_CHARACTER]: '国・地域名に使用できない文字が含まれています。',
  // 重複する国・地域名（略称）
  [RESPONSE_MESSAGE.DUPLICATE_REGION_ABBREVIATION]: '国・地域名（略称）が重複しているため、保存できません。',
  // 国・地域名（略称）にnull文字
  [RESPONSE_MESSAGE.REGION_ABBREVIATION_NO_NULL_CHARACTER]: '国・地域名（略称）に使用できない文字が含まれています。',

  // 取り込みルール
  // 基準値なしとして扱う文字列にnull文字
  [RESPONSE_MESSAGE.IGNORE_CRITERION_KEYWORDS_NO_NULL_CHARACTER]:
    '基準値なしとして扱う文字列に使用できない文字が含まれています。',

  // CSVの読み込み
  // 読み込んだファイルがCSVではない場合
  [RESPONSE_MESSAGE.INVALID_FILE_FORMAT]: 'ファイルの形式が不正です。',
  // CSVの列が不正
  [RESPONSE_MESSAGE.INVALID_CSV_COLUMNS]: 'ファイルの形式が不正です。',

  // アカウント
  // アカウントのログインIDの重複
  [RESPONSE_MESSAGE.DUPLICATE_KEY_ACCOUNT_LOGIN_ID]: 'ログインIDが既に使⽤されているIDのため、保存出来ません。',
  // ログインIDが長すぎる
  [RESPONSE_MESSAGE.ACCOUNT_LOGIN_ID_TOO_LONG]: 'ログインIDが長すぎます',
  // ログインIDが短すぎる
  [RESPONSE_MESSAGE.ACCOUNT_LOGIN_ID_TOO_SHORT]: 'ログインIDが短すぎます',
  // アカウントIDにnull文字が含まれる
  [RESPONSE_MESSAGE.ACCOUNT_LOGIN_ID_NO_NULL_CHARACTER]: 'ログインIDに使用できない文字が含まれています。',
  // アカウント名が長すぎる
  [RESPONSE_MESSAGE.ACCOUNT_NAME_TOO_LONG]: 'アカウント名が長すぎます。',
  // アカウント名が右かすぎる
  [RESPONSE_MESSAGE.ACCOUNT_NAME_TOO_SHORT]: 'アカウント名が短すぎます。',
  // アカウント名にnull文字が含まれる
  [RESPONSE_MESSAGE.ACCOUNT_NAME_NO_NULL_CHARACTER]: 'アカウント名に使用できない文字が含まれています。',

  // ログインフォーム
  // ログインIDかパスワードが違う
  [RESPONSE_MESSAGE.INVALID_ID_OR_PASSWORD]: 'ログインIDかパスワードが正しく有りません。',
  // ユーザーは停止中
  [RESPONSE_MESSAGE.INVALID_STATE]: 'アカウントは停止中です。管理者にお問い合わせください。',

  // アカウントの権限
  // 検体情報を変更する権限がない
  [RESPONSE_MESSAGE.NO_AUTHORITY_FOR_EDIT_SAMPLE]: '検体情報を編集する権限がありません。',
  // 分析結果を変更する権限がない
  [RESPONSE_MESSAGE.NO_AUTHORITY_FOR_EDIT_ANALYSIS_RESULT]: '分析結果を編集する権限がありません。',
  // 検体について特定の状態変更できる権限がない
  [RESPONSE_MESSAGE.NO_AUTHORITY_TO_CHANGE_SAMPLE_STATE]: '検体情報の状態を変更する権限がありません。',

  // 共通のエラー
  // 有効なアカウントでない
  [RESPONSE_MESSAGE.NOT_VALID_ACCOUNT]: 'アカウントが無効です。',
  '登録できませんでした。': '登録できませんでした。',
  '削除できませんでした。': '削除できませんでした。',
  '処理が完了できませんでした。': '処理が完了できませんでした。',
  ログインできませんでした: 'ログインできませんでした',
  // 要素を満たしていない
  [RESPONSE_MESSAGE.INVALID_BODY]: '入力された値に不備があります。',

  // 特別なエラー(resolveErrorMessageで特別扱いしている)
  AccessDeniedError: '権限がありません。',
  TooManyRequestsError: 'アクセス制限に達しました。',
  AuthorizationRequiredError: 'ログインが必要です。',

  // react-adminが直接返すエラー
  '{"name":"AuthorizationRequiredError","message":"Authorization is required for request on GET /api/accounts/me","httpCode":401}':
    'ログインが必要です。',

  // ボタンのラベルで使用
  新規登録: '新規登録',
  判定基準の追加: '判定基準の追加',
  国を追加: '国を追加',
  アカウントを追加: 'アカウントを追加',

  ...japaneseMessages,
}
