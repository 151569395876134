import { Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/system'
import { AccountRole, EDITABLE_STATE_LIST_DICTIONARY, ENTITY_CONST } from 'dto'
import { useMemo } from 'react'
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
  useGetOne,
  useNotify,
  usePermissions,
  useRedirect,
  useUpdate,
  SimpleFormIterator,
  maxLength,
  number,
  maxValue,
  minValue,
} from 'react-admin'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { SAMPLE_STATE_DICTIONARY } from '../const'
import { AnalysisResults, CriterionPesticide, JudgementCriterion, Sample } from '../types'
import { nullToEmptyTextParser } from '../utils/null_to_empty_text_parser'
import { FETCH_PARAMS_FOR_THOUSAND_ITEMS } from '../utils/parameters'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'
import { dateValidator } from '../utils/validator'

const DEFAULT_TITLE = '分析・判定結果'

// 保存ボタン押下時の挙動、削除ボタンの確認ダイアログ等をカスタム
// 分析・判定結果画面のみ、保存ボタンのラベルが「登録する」になっている
// TODO: 削除確認ダイアログの選択肢を「確認」→「削除」にする
const CustomToolbar = ({
  showDelete,
  target,
  baseRedirect,
}: {
  showDelete: boolean
  target?: string
  baseRedirect?: string
}) => {
  const [update] = useUpdate()
  const { getValues, formState, handleSubmit } = useFormContext()
  const redirect = useRedirect()
  const notify = useNotify()

  // 削除時エラー用
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '削除できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // 保存ボタン押下時の挙動カスタム (callするエンドポイント等)
  const save = () => {
    // 必要・不要プロパティ切り分け
    const {
      updatedAccount: _updatedAccount,
      updatedTimestamp: _updatedTimestamp,
      created_at: _created_at,
      updated_at: _updated_at,
      totalOutOfRefStandardValue: _totalOutOfRefStandardValue,
      totalEvaluationGoodRegionIds: _totalEvaluationGoodRegionIds,
      totalEvaluationBadRegionIds: _totalEvaluationBadRegionIds,
      totalEvaluationMediumRegionIds: _totalEvaluationMediumRegionIds,
      isResultsRegistered: _isResultsRegistered,

      // 以下、必要なプロパティ
      id,
      analysisResults,
      judgementCriterion,
      notDetected,
      ...data
    } = getValues()

    // 検出無しトグルボタンがonの時、空配列を返す (offのときは、入力値を成形して返す)
    const formattedAnalysisResults = notDetected
      ? []
      : analysisResults.map((analysisResult: { detectedComponentName: string; detectedValuePpm: number }) => ({
          detectedComponentName: analysisResult.detectedComponentName,
          detectedValuePpm: analysisResult.detectedValuePpm.toString(),
        }))

    const formattedData = {
      ...data,
      analysisRemarks: nullToEmptyTextParser(data.analysisRemarks),
      analysisResults: formattedAnalysisResults,
      judgementCriterionId: judgementCriterion.id,
      isResultsRegistered: !(data.sampleState === 'unanalyzed' && formattedAnalysisResults.length <= 0 && !notDetected),
    }

    update(
      `samples/${id}/with_results`,
      { id: '', data: formattedData },
      {
        onSuccess: () => {
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' })
          redirect(`/samples/${id}/show`)
        },
        onError: (error: unknown) => {
          const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
          notify(errorMessage, { type: 'error' })
        },
      }
    )
  }

  return (
    <Toolbar>
      <SaveButton disabled={formState.isSubmitting} label="この内容で登録する" onClick={handleSubmit(() => save())} />
      {showDelete && (
        <DeleteWithConfirmButton
          confirmContent="よろしいですか？"
          confirmTitle={`${target}を削除します。`}
          mutationOptions={{ onError }}
          redirect={baseRedirect}
          sx={{ marginLeft: 'auto' }}
        />
      )}
    </Toolbar>
  )
}

// 成分名サジェスト用コンポーネント (選択肢以外も入力を認めるようカスタム)
const CustomAutoCompleteInput = ({ source, label, options }: { source: string; label: string; options: string[] }) => {
  const { field } = useController({ name: source, defaultValue: '' })

  return (
    <Autocomplete
      defaultValue={field.value}
      freeSolo
      onBlur={field.onBlur}
      onChange={(_e, value) => {
        field.onChange(value)
      }}
      options={options}
      renderInput={({ ...params }) => (
        <TextField
          {...params}
          inputRef={field.ref}
          label={label}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
        />
      )}
      sx={{ minWidth: '398px', maxWidth: '398px' }}
      value={field.value}
    />
  )
}

const AnalysisResultInputWrapper = ({
  defaultValue,
  defaultId,
}: {
  defaultValue: AnalysisResults[]
  defaultId: number | undefined
}) => {
  // 検出成分、検出値の入力欄を表示するか切り替えるための情報取得 (trueで非表示(検出成分がないため))
  const notDetected = useWatch<{ notDetected: boolean }>({ name: 'notDetected' })

  // 検出成分のサジェスト用 選択肢取得するために、判定基準の入力欄の情報取得
  // 選択中の判定基準に紐づく基準値を選択肢としてサジェスト
  const judgementCriterion = useWatch<{ judgementCriterion: JudgementCriterion }>({
    name: 'judgementCriterion',
  }) as JudgementCriterion

  // 判定基準が一つも存在しないときは、サジェスト無しの入力欄を表示
  if (!judgementCriterion || !defaultId) {
    return <>{notDetected || <NoChoiceAnalysisResultInput defaultValue={defaultValue} />}</>
  }

  // 判定基準があるときは、サジェスト付き入力欄を表示
  return (
    <>
      {notDetected || (
        <AnalysisResultInput
          defaultId={defaultId}
          defaultValue={defaultValue}
          judgementCriterion={judgementCriterion}
        />
      )}
    </>
  )
}

const CustomArrayInput = ({
  defaultValue,
  options = [],
}: {
  defaultValue: AnalysisResults[]
  options: CriterionPesticide[]
}) => {
  return (
    <ArrayInput defaultValue={defaultValue} label="" source="analysisResults">
      <SimpleFormIterator disableReordering inline>
        <CustomAutoCompleteInput
          label="検出農薬成分"
          options={options.map((p) => p.pesticidesNameJapanese)}
          source="detectedComponentName"
        />
        <NumberInputAnalysisResult
          label="検出値(ppm)"
          source="detectedValuePpm"
          validate={[
            required('数値を入力してください'),
            number('数値を入力してください'),
            maxValue(9999999.9999),
            minValue(0.0001),
          ]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

// 分析結果(検出成分名・検出値) 入力用コンポーネント
// TODO: 登録された検出成分を消しても、再読み込みするまで値が残ってしまう？
const AnalysisResultInput = ({
  defaultValue,
  defaultId,
  judgementCriterion,
}: {
  defaultValue: AnalysisResults[]
  defaultId: number
  judgementCriterion: JudgementCriterion
}) => {
  // サジェスト用選択肢取得
  const {
    data: pesticides,
    isLoading,
    isFetchedAfterMount,
  } = useGetList<CriterionPesticide>(
    `judgement_criteria/${judgementCriterion?.id ?? defaultId}/pesticides`,
    FETCH_PARAMS_FOR_THOUSAND_ITEMS
  )
  if (isLoading || !pesticides || !isFetchedAfterMount) {
    return null
  }

  return <CustomArrayInput defaultValue={defaultValue} options={pesticides} />
}

// 判定基準が一つもないときに表示
const NoChoiceAnalysisResultInput = ({ defaultValue }: { defaultValue: AnalysisResults[] }) => {
  return <CustomArrayInput defaultValue={defaultValue} options={[]} />
}

// 分析・判定結果 編集用コンポーネント
// HACK: 画面の幅を小さくすると、それに応じて入力欄の横幅が変わるが、その変わり方が入力系コンポーネントで異なる。
// これを統一するために、以下ではminWidthとmaxWidthで同一の値を指定している。
const inputWidth = 268

const SelectInputAnalysisResult = styled(SelectInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const DateInputAnalysisResult = styled(DateInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const RemarkInput = styled(TextInput)({
  width: '100%',
})

const NumberInputAnalysisResult = styled(NumberInput)({
  minWidth: '176px',
  maxWidth: '176px',
})

// TODO: なぜか日付のバリデーションが動かない。そもそもonErrorの中にサーバエラーでも入らない。
export const AnalysisResultEdit = () => {
  const { id } = useParams()
  const { permissions: currentRole } = usePermissions<AccountRole>()
  const editableStateList = EDITABLE_STATE_LIST_DICTIONARY[currentRole]
  const isAdmin = currentRole === 'admin'

  // エラーメッセージ通知準備
  const notify = useNotify()
  const onError = (error: unknown) => {
    // ここ動いていない謎がある
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // 検体情報取得
  const {
    data: sample,
    isLoading: sampleIsLoading,
    isFetchedAfterMount: sampleIsFetchedAfterMount,
  } = useGetOne<Sample>('samples', { id: Number(id) })

  // 状態変更の選択肢
  const choices = useMemo(() => {
    const choicesList = sample ? [sample.sampleState, ...editableStateList] : editableStateList
    return Array.from(new Set(choicesList)).map((id) => ({
      id,
      name: SAMPLE_STATE_DICTIONARY[id],
    }))
  }, [sample?.sampleState, editableStateList])

  // 判定基準 選択肢作成用、情報取得
  // todo: 専用APIを用意する。https://water-cell.backlog.com/view/ITOEN2023-288
  const {
    data: judgementCriteria,
    isLoading: judgementCriteriaIsLoading,
    isFetchedAfterMount: judgementCriteriaIsFetchedAfterMount,
  } = useGetList<JudgementCriterion>('judgement_criteria', {
    ...FETCH_PARAMS_FOR_THOUSAND_ITEMS,
    sort: { field: 'updatedTimestamp', order: 'DESC' },
  })

  // 分析結果 取得
  const {
    data: results,
    isLoading: resultsIsLoading,
    isFetchedAfterMount: resultsIsFetchedAfterMount,
  } = useGetList<AnalysisResults>(`samples/${id}/results`)

  if (
    sampleIsLoading ||
    judgementCriteriaIsLoading ||
    resultsIsLoading ||
    !sample ||
    !judgementCriteria ||
    !results ||
    !sampleIsFetchedAfterMount ||
    !judgementCriteriaIsFetchedAfterMount ||
    !resultsIsFetchedAfterMount
  ) {
    return null
  }

  // 判定基準のデフォルト値決定
  // 優先順位 ① 検体に紐づく判定基準 ② デフォルトで適用する判定基準 ③ 最新の判定基準
  const defaultJudgementCriterion = judgementCriteria.find((j) => j.isDefaultApplied)
  const recentJudgementCriterion = judgementCriteria[0] ?? []
  const judgementCriterionDefaultValue =
    sample.judgementCriterion ?? defaultJudgementCriterion ?? recentJudgementCriterion

  // 「検出無し」で分析結果が登録されているのか判定
  const isNotDetected = results?.length === 0 && sample?.isResultsRegistered

  const defaultValues = {
    ...sample,
    analysisResults: results,
    judgementCriterion: judgementCriterionDefaultValue,
  }

  return (
    <Edit
      actions={false}
      id={id}
      mutationMode={'pessimistic'}
      mutationOptions={{ onError }}
      resource={'samples'}
      title={DEFAULT_TITLE + ' > 分析結果の登録・編集'}
    >
      <SimpleForm
        defaultValues={defaultValues}
        toolbar={<CustomToolbar baseRedirect={'/samples'} showDelete={false} />}
      >
        <Typography variant="h5">分析結果の登録・編集</Typography>
        <p>管理情報</p>
        <SelectInputAnalysisResult
          choices={choices}
          disabled={!editableStateList.includes(sample.sampleState)}
          label="状態"
          source="sampleState"
          validate={required()}
        />
        <DateInputAnalysisResult label="検査終了日" source="inspectEndDate" validate={dateValidator} />
        <RemarkInput label="備考" source="analysisRemarks" validate={maxLength(ENTITY_CONST.MAX_LEN_FOR_MEMO)} />
        <p>判定情報</p>
        <SelectInputAnalysisResult
          choices={judgementCriteria}
          defaultValue={judgementCriterionDefaultValue.id}
          label="判定基準"
          optionText="name"
          source="judgementCriterion.id"
          validate={required()}
        />
        <TextInput defaultValue={sample.processingFactor} label="加工係数" source="processingFactor" type="number" />
        <p>分析結果</p>
        <BooleanInput defaultValue={isNotDetected} label="検出無し" source="notDetected" />
        <AnalysisResultInputWrapper defaultId={judgementCriterionDefaultValue.id} defaultValue={results} />
        <BooleanInput disabled={!isAdmin} label="全ての総評を✕とする" source="forceAllTotalEvaluationBad" />
      </SimpleForm>
    </Edit>
  )
}
