import { AuthLoginRequest, AccountGetMeResponse } from 'dto'
import { AuthProvider } from 'react-admin'

import { get, post } from './requester'

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const signInRequest = new AuthLoginRequest()
    signInRequest.loginId = username
    signInRequest.password = password

    return post('/api/auth/login', signInRequest)
  },
  logout: async () => {
    return post('/api/auth/logout')
  },
  checkAuth: async () => {
    try {
      await get('/api/accounts/me')
      return
    } catch (err) {
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        // fetch自体がの失敗した場合は無視する
        return
      }
      throw err
    }
  },
  checkError: async (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      return post('/api/auth/logout')
    }
    return Promise.resolve()
  },
  getIdentity: async () => {
    const { data } = await get<AccountGetMeResponse>('/api/accounts/me')
    return {
      id: data.id,
      fullName: data.name,
    }
  },
  getPermissions: async () => {
    const me = await get<AccountGetMeResponse>('/api/accounts/me')
    return me.data.role
  },
}
