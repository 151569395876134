import Encoding from 'encoding-japanese'

export function fileToString(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = (event) => {
      const result = event.target?.result
      if (result && typeof result !== 'string') {
        const encode = Encoding.detect(new Uint8Array(result)) || 'SJIS'
        resolve(new TextDecoder(encode).decode(result))
      } else if (typeof result === 'string') {
        resolve(result)
      } else {
        resolve('')
      }
    }

    reader.onerror = () => {
      reject(new Error('File reading error'))
    }

    reader.onabort = () => {
      reject(new Error('File reading aborted'))
    }

    reader.readAsArrayBuffer(file)
  })
}
