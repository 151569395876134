import AccountCircle from '@mui/icons-material/AccountCircle'
import { Tooltip, IconButton, Menu, Button, Avatar, PopoverOrigin, useMediaQuery, Theme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AccountRole } from 'dto'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Logout, UserMenuContext, useAuthProvider, useGetIdentity, useTranslate, usePermissions } from 'react-admin'

import { ROLE_DICTIONARY } from '../const'

export interface CustomUserMenuProps {
  children?: ReactNode
  className?: string
  label?: string
  icon?: ReactNode
}

// HACK: UserMenuをベースにカスタム
export const CustomUserMenu = (props: CustomUserMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null)
  const translate = useTranslate()
  const { isLoading, identity } = useGetIdentity()
  const authProvider = useAuthProvider()
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))

  // HACK: 権限名取得
  const { isLoading: isPermissionLoading, permissions } = usePermissions<AccountRole>()
  const roleText = isPermissionLoading ? '' : ROLE_DICTIONARY[permissions]

  const {
    children = authProvider ? <Logout /> : null,
    className,
    label = 'ra.auth.user_menu',
    icon = defaultIcon,
  } = props

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget)
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const context = useMemo(() => ({ onClose: handleClose }), [handleClose])
  if (!children) {
    return null
  }

  const open = Boolean(anchorEl)

  return (
    <Root className={className}>
      {isLargeEnough && !isLoading && identity?.fullName ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={UserMenuClasses.userButton}
          color="inherit"
          onClick={handleMenu}
          startIcon={
            identity.avatar ? (
              <Avatar alt={identity.fullName} className={UserMenuClasses.avatar} src={identity.avatar} />
            ) : (
              icon
            )
          }
        >
          {/* HACK: 権限を表示 */}
          {`${identity.fullName} (${roleText})`}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: 'Profile' })}>
          <IconButton
            aria-haspopup={true}
            aria-label={label && translate(label, { _: 'Profile' })}
            // HACK: nullだとエラーになるので(void 0=undefined)に変更
            aria-owns={open ? 'menu-appbar' : void 0}
            color="inherit"
            onClick={handleMenu}
          >
            {!isLoading && identity?.avatar ? (
              <Avatar alt={identity.fullName} className={UserMenuClasses.avatar} src={identity.avatar} />
            ) : (
              icon
            )}
          </IconButton>
        </Tooltip>
      )}
      <UserMenuContext.Provider value={context}>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={AnchorOrigin}
          disableScrollLock
          id="menu-appbar"
          onClose={handleClose}
          open={open}
          transformOrigin={TransformOrigin}
        >
          {children}
        </Menu>
      </UserMenuContext.Provider>
    </Root>
  )
}

export interface UserMenuProps {
  children?: ReactNode
  className?: string
  label?: string
  icon?: ReactNode
}

const PREFIX = 'RaUserMenu'

export const UserMenuClasses = {
  userButton: `${PREFIX}-userButton`,
  avatar: `${PREFIX}-avatar`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${UserMenuClasses.userButton}`]: {
    textTransform: 'none',
    marginInlineStart: theme.spacing(0.5),
  },

  [`& .${UserMenuClasses.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const defaultIcon = <AccountCircle />

const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
