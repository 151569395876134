"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SamplePostExportCSVResponse = void 0;
const common_1 = require("../common");
class CSVResponse {
    // このidはreact-adminの仕様で必要なもの(意味はない)
    id;
    csv;
}
class SamplePostExportCSVResponse extends common_1.ResponseBase {
}
exports.SamplePostExportCSVResponse = SamplePostExportCSVResponse;
