import {
  JudgementCriterionParseDataCsvPostRequest,
  JudgementCriterionParseDataCsvPostResponse,
  JudgementCriterionAllDataCreate,
  ListResponseBase,
  ResponseBase,
  SamplePostAllResponse,
  SampleParseCsvPostRequest,
  SampleParseCsvPostResponse,
} from 'dto'
import { compressToBase64 } from 'lz-string'
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyResult,
  GetManyReferenceResult,
  CreateParams,
  CreateResult,
  DeleteResult,
  DeleteManyResult,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  UpdateManyResult,
  DeleteParams,
  DeleteManyParams,
} from 'react-admin'

import { JudgementCriterion, ParsedCsvPesticideDataItem, Sample } from '../types'

import { get, post, put, del } from './requester'

const API_URL = '/api'

type JudgementCriterionCreateManyParams = {
  pesticideDataItems: ParsedCsvPesticideDataItem
  judgementCriterion: JudgementCriterion
}

type SampleCreateManyParams = {
  samples: Sample[]
}

export const dataProvider: DataProvider = {
  getList: async (resource: string, params: GetListParams): Promise<GetListResult> => {
    const { pagination, sort, filter } = params
    const { page, perPage } = pagination
    const { field, order } = sort

    // HACK:paging処理で問題抱えているので、samplesだけ例外的にクライアントで処理する
    if (resource === 'samples') {
      const data = []
      let internalPage = 1
      let hasNext = true
      while (hasNext) {
        const res = await get<ListResponseBase<unknown[]>>(`${API_URL}/${resource}`, {
          _perPage: 500, // 一旦このくらいにするが、この数値が低いと表示に時間かかるし、高いとサーバー負荷が大きい
          _page: internalPage,
          _sortField: field,
          _sortOrder: order,
          ...filter,
        })
        data.push(...res.data)
        internalPage++
        hasNext = res.pageInfo.hasNextPage
      }
      const total = data.length
      const startIndex = (page - 1) * perPage
      const endIndex = startIndex + perPage
      const pagingSamples = data.slice(startIndex, endIndex)
      return {
        data: pagingSamples,
        total: total,
        pageInfo: {
          hasNextPage: page < Math.ceil(total / perPage),
          hasPreviousPage: page > 1,
        },
      }
    }

    const res = await get<ListResponseBase<unknown[]>>(`${API_URL}/${resource}`, {
      _perPage: perPage,
      _page: page,
      _sortField: field,
      _sortOrder: order,
      ...filter,
    })

    return {
      data: res.data,
      total: res.total,
      pageInfo: res.pageInfo,
    }
  },
  getOne: async (resource: string, params: GetOneParams): Promise<GetOneResult> => {
    const res = await get<ResponseBase<unknown>>(`${API_URL}/${resource}/${params.id}`)
    return {
      data: res.data,
    }
  },
  getMany: async (resource: string, params: GetManyParams): Promise<GetManyResult> => {
    const records: unknown[] = []
    const { ids } = params
    // promise allだとサーバーに負荷がかかるのであえてのfor
    for (const id of ids) {
      const res = await get<ResponseBase<unknown>>(`${API_URL}/${resource}/${id}`)
      records.push(res.data)
    }
    return {
      data: records,
    }
  },
  getManyReference: () => {
    return Promise.resolve({} as GetManyReferenceResult)
  },
  create: async (resource: string, params: CreateParams): Promise<CreateResult> => {
    const { data } = await post<ResponseBase<unknown>>(`${API_URL}/${resource}`, { ...params.data })
    return {
      data,
    }
  },
  update: async (resource: string, params: UpdateParams): Promise<UpdateResult> => {
    const { data } = await put<ResponseBase<unknown>>(`${API_URL}/${resource}/${params.id}`, { ...params.data })
    return {
      data,
    }
  },
  updateMany: async (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    const records: unknown[] = []
    const { ids } = params
    // promise allだとサーバーに負荷がかかるのであえてのfor
    for (const id of ids) {
      const res = await put<ResponseBase<unknown>>(`${API_URL}/${resource}/${id}`)
      records.push(res.data)
    }
    return {
      data: records,
    }
  },
  delete: async (resource: string, params: DeleteParams): Promise<DeleteResult> => {
    const { data } = await del<ResponseBase<unknown>>(`${API_URL}/${resource}/${params.id}`)
    return {
      data,
    }
  },
  deleteMany: async (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    const records: unknown[] = []
    const { ids } = params
    // promise allだとサーバーに負荷がかかるのであえてのfor
    for (const id of ids) {
      const res = await del<ResponseBase<unknown>>(`${API_URL}/${resource}/${id}`)
      records.push(res.data)
    }
    return {
      data: records,
    }
  },
  // 判定基準CSVの解析
  parseJudgementCriterionCsv: async (
    params: JudgementCriterionParseDataCsvPostRequest
  ): Promise<JudgementCriterionParseDataCsvPostResponse> => {
    return await post<JudgementCriterionParseDataCsvPostResponse>(`${API_URL}/judgement_criteria/parse_data_csv`, {
      ...params,
    })
  },
  // 判定基準 一括登録
  judgementCriterionCreateMany: async (
    params: JudgementCriterionCreateManyParams
  ): Promise<JudgementCriterionAllDataCreate> => {
    const { data } = await post<JudgementCriterionAllDataCreate>(`${API_URL}/judgement_criteria/all_data_create`, {
      ...params,
      pesticideDataItems: compressToBase64(JSON.stringify(params.pesticideDataItems)), // HACK: サイズが大きくなりがちなので圧縮する
    })
    return { data }
  },
  // 検体情報CSVの解析
  parseSampleCsv: async (params: SampleParseCsvPostRequest): Promise<SampleParseCsvPostResponse> => {
    return await post<SampleParseCsvPostResponse>(`${API_URL}/samples/parse_data_csv`, { ...params })
  },
  // 検体情報 一括登録
  sampleCreateMany: async (params: SampleCreateManyParams): Promise<SamplePostAllResponse> => {
    const { data } = await post<SamplePostAllResponse>(`${API_URL}/samples/all`, { ...params })
    return { data }
  },
}
