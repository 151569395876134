import { Show, TabbedShowLayout, usePermissions } from 'react-admin'

import { CriterionValueTab } from './CriterionValueTab'
import { OverviewJudgementTab } from './OverviewJudgementTab'

const DEFAULT_TITLE = '判定基準管理'

// 詳細ページ大枠
export const JudgementCriterionDetails = () => {
  // 管理者または承認者のみ編集・追加・削除が可能
  const { permissions } = usePermissions()
  const isEditable = permissions === 'admin' || permissions === 'approver'

  return (
    <Show actions={false} sx={{ marginTop: '24px' }} title={DEFAULT_TITLE}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="概要・判定設定">
          <OverviewJudgementTab isEditable={isEditable} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="判定基準" path="criterion_values">
          <CriterionValueTab isEditable={isEditable} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  )
}
