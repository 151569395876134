"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultGetOneResponse = void 0;
const common_1 = require("../common");
class Sample {
    id;
    sampleNo;
    sampleState;
    requestDepartment;
    requester;
    requestedDate;
    divisionOne;
    divisionTwo;
    management;
    productCode;
    productName;
    sampleAdditionalInfo;
    destination;
    manufacturer;
    judgementDesiredDate;
    judgementScheduledDate;
    sampleRemarks;
    processingFactor;
    inspectEndDate;
    analysisRemarks;
    updatedTimestamp;
    isResultsRegistered;
    forceAllTotalEvaluationBad;
}
class AnalysisResult {
    id;
    detectedComponentName;
    detectedValuePpm;
    sample;
    // 以下は判定結果
    // 参照基準値外
    outOfRefStandardValue;
    // 判定〇の地域ID
    evaluationGoodRegionIds;
    // 判定△の地域ID
    evaluationMediumRegionIds;
    // 判定×の地域ID
    evaluationBadRegionIds;
}
class ResultGetOneResponse extends common_1.ResponseBase {
}
exports.ResultGetOneResponse = ResultGetOneResponse;
