import EditIcon from '@mui/icons-material/Edit'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { AccountRole, EDITABLE_STATE_LIST_DICTIONARY, SampleState } from 'dto'
import { useState, useMemo, type ChangeEvent } from 'react'
import { useNotify, useRefresh, useListContext, useUpdate, required, SelectInput } from 'react-admin'
import { Form, usePermissions, type RaRecord } from 'react-admin'

import { SAMPLE_STATE_DICTIONARY } from '../const'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

export const BulkUpdateWithSampleStatus = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { selectedIds, onUnselectItems } = useListContext()
  const [update, { isLoading }] = useUpdate()

  const handleClick = (sampleState: SampleState) => {
    const data = {
      id: '',
      ids: selectedIds,
      sampleState,
    }
    update(
      'samples/sample_state',
      { id: '', data },
      {
        onSuccess: () => {
          notify('ra.notification.updated', {
            type: 'info',
            messageArgs: { smart_count: selectedIds.length },
          })
          onUnselectItems()
          refresh()
        },
        onError: (error: unknown) => {
          const errorMessage = resolveErrorMessage(error, '処理が完了できませんでした。')
          notify(errorMessage, { type: 'error' })
          refresh()
        },
      }
    )
  }

  return (
    <>
      <StyledButton disabled={isLoading} onClick={() => setIsOpen(true)} size="small" startIcon={<EditIcon />}>
        状態の変更
      </StyledButton>
      <SelectSampleStateDialog handleClose={() => setIsOpen(false)} handleConfirm={handleClick} isOpen={isOpen} />
    </>
  )
}

// React-AdminのBulkUpdateButtonのstyle実装をコピペ
const StyledButton = styled(Button, {
  name: 'RaButton',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    // Reset on mouse devices
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
  '&.MuiButton-sizeSmall': {
    // fix for icon misalignment on small buttons, see https://github.com/mui/material-ui/pull/30240
    lineHeight: 1.5,
  },
}))

const SelectSampleStateDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
}: {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: (sampleState: SampleState) => void
}) => {
  const { permissions: currentRole } = usePermissions<AccountRole>()
  const editableStateList = EDITABLE_STATE_LIST_DICTIONARY[currentRole]

  const choices = useMemo(() => {
    return Array.from(new Set(editableStateList)).map((id) => ({ id, name: SAMPLE_STATE_DICTIONARY[id] }))
  }, [editableStateList])

  const [selectedState, setSelectedState] = useState<SampleState | ''>('')

  const onChangeSelect = (e: ChangeEvent<HTMLInputElement> | RaRecord) => {
    setSelectedState(e.target.value)
  }

  const onClickConfirm = () => {
    if (!selectedState) {
      return
    }
    handleConfirm(selectedState)
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle>状態の変更</DialogTitle>
      <DialogContent>
        <Form>
          <SelectInput
            choices={choices}
            label="状態"
            onChange={onChangeSelect}
            source="sampleState"
            validate={required()}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button disabled={!selectedState} onClick={onClickConfirm}>
          変更する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
