"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegionSummaryGetAllResponse = void 0;
const common_1 = require("../common");
class RegionSummary {
    id;
    regionName;
}
class RegionSummaryGetAllResponse extends common_1.ResponseBase {
}
exports.RegionSummaryGetAllResponse = RegionSummaryGetAllResponse;
