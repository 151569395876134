"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountGetAllResponse = void 0;
const common_1 = require("../common");
class Account {
    id;
    loginId;
    name;
    role;
    state;
}
class AccountGetAllResponse extends common_1.ListResponseBase {
}
exports.AccountGetAllResponse = AccountGetAllResponse;
