"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PesticideGetAllQuery = void 0;
const class_validator_1 = require("class-validator");
const const_1 = require("../const");
class PesticideGetAllQuery {
    _perPage;
    _page;
    // 以下フィルタ
    //農薬の有効成分 (成分名の和名) (部分一致)
    pesticidesNameJapanese_contains;
}
__decorate([
    (0, class_validator_1.Max)(const_1.LIST_CONST.MAX_PAGE_NUM),
    (0, class_validator_1.Min)(const_1.LIST_CONST.MIN_PAGE_NUM),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PesticideGetAllQuery.prototype, "_perPage", void 0);
__decorate([
    (0, class_validator_1.Min)(const_1.LIST_CONST.MIN_PAGE_NUM),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PesticideGetAllQuery.prototype, "_page", void 0);
__decorate([
    (0, class_validator_1.MaxLength)(const_1.ENTITY_CONST.MAX_LEN_FOR_LABEL),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], PesticideGetAllQuery.prototype, "pesticidesNameJapanese_contains", void 0);
exports.PesticideGetAllQuery = PesticideGetAllQuery;
