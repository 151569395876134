import AddIcon from '@mui/icons-material/Add'
import ContentCreate from '@mui/icons-material/Create'
import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { format } from 'date-fns'
import { compressToBase64 } from 'lz-string'
import { useState } from 'react'
import {
  Create,
  Datagrid,
  Edit,
  FileInput,
  NumberInput,
  List,
  required,
  SaveButton,
  SelectInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreate,
  useDataProvider,
  useGetList,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  maxLength,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { CRITERION_STATE_DICTIONARY } from '../const'
import CsvFormatSample from '../images/csv_format_sample.png'
import { overflowListStyle } from '../layout/overflowStyle'
import { CriterionPesticide, ParsedCsvPesticideDataItem, Region } from '../types'
import { fileToString } from '../utils'
import { FETCH_PARAMS_FOR_THOUSAND_ITEMS } from '../utils/parameters'
import { preventEnterRegist } from '../utils/preventEnterRegist'
import { resolveErrorMessage } from '../utils/resolveErrorMessage'

const DEFAULT_TITLE = '判定基準管理'

// HACK: 画面の幅を小さくすると、それに応じて入力欄の横幅が変わるが、その変わり方がSelectInput, TextInput, PasswordInputで異なる。
// これを統一するために、以下ではminWidthとmaxWidthで同一の値を指定している。
const inputWidth = 252
const descriptionMargin = 17

const SelectInputOverviewJudgement = styled(SelectInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const SelectArrayInputOverviewJudgement = styled(SelectArrayInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const TextInputOverviewJudgement = styled(TextInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const NumberInputOverviewJudgement = styled(NumberInput)({
  minWidth: inputWidth,
  maxWidth: inputWidth,
})

const SpanOverviewJudgement = styled('span')({
  marginLeft: descriptionMargin,
})

const DivOverviewJudgement = styled('div')({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
})

const PWithNoMargin = styled('p')({
  marginTop: '0px',
  marginBottom: '0px',
})

const FileInputJudgementEdit = styled(FileInput)({
  '& .RaFileInput-dropZone': {
    height: '107px',
    background: '#EBEBEB 0% 0% no-repeat padding-box',
    border: ' 2px dashed #BDBDBD',
    paddingTop: '30px',
  },
})

const StyledCsvExportLink = styled('a')({
  marginTop: '16px',
  marginBottom: '16px',
  color: '#00A040',
})

const TypographyCsvUpload = styled(Typography)({
  marginTop: '18px',
  marginBottom: '21px',
})

const PPlaceHolder = styled('p')({
  fontSize: '13px',
  color: '#868686',
})

const ImgCsv = styled('img')({
  height: '118px',
  width: '317px',
  marginTop: '21px',
  marginBottom: '16px',
})

const FileInputJudgementCreate = styled(FileInput)({
  '& .RaFileInput-dropZone': {
    height: '156px',
    background: '#EBEBEB 0% 0% no-repeat padding-box',
    border: ' 2px dashed #BDBDBD',
    paddingTop: '48px',
  },
})

// 概要・判定基準設定の追加・編集時、入力欄の右側に表示する説明文定義
const nameDescription = '他の判定基準と区別できる名前を設定してください。'
const isDefaultAppliedDescription = '「する」の場合、新規に判定結果を登録する際に標準で選択されます。'
const mediumThresholdDescription = '分析値が基準値 × 倍率より大きく基準値以下の場合に判定を△として扱います。'
const mediumAppliedRegionsDescription = '指定した国・地域に上記ルールを適用します。'
const undetectableThresholdDescription = '基準値が指定値未満の場合、「不検出」と同様に判定を行います。'
const undetectableNotAppliedRegionsDescription =
  '指定した国・地域は上記の設定に関わらず判定基準値通りの判定を行います。'

// バリデーションの設定
const validationName = required('名前は空欄にできません')
const validationNameLength = maxLength(250, '名前は250文字以下にしてください')
const validationIsDefaultApplied = required('標準で適用するかは空欄にできません')
const validationMediumThreshold = required('判定を△とする基準は空欄にできません')
const validationUndetectableThreshold = required('不検出と同様に扱う閾値は空欄にできません')

// -- 以下、判定基準タブ関係 -- //
// 基準値を表示するための自作field
const CriterionValueField = ({ regionId }: { label: string; regionId: number; sortable: boolean; source: string }) => {
  const record = useRecordContext<CriterionPesticide>()
  if (!record) {
    return null
  }

  // 該当列のデータだけ取得
  const targetRecord = record.criterionDataItems.find((r) => r.region.id === regionId)
  if (typeof targetRecord === 'undefined') {
    return <div>不</div>
  }

  // criterionStateが'valid'のとき、criterionValueが代入される
  const criterionValue =
    CRITERION_STATE_DICTIONARY[targetRecord.criterionState] || targetRecord.criterionValue.toString()
  const leftOrRight = targetRecord.criterionState === 'valid' ? 'right' : 'left'

  return <div style={{ textAlign: leftOrRight }}>{criterionValue}</div>
}

// 判定基準タブ中身
// HACK: 基準値一覧をfilteringするために、useListContextを使い<List>でAPIから取得したデータを成形し、<Datagrid>に渡している
const CriterionValueTabContents = () => {
  // 基準値一覧データ <List>から取得
  const { data, total, isLoading } = useListContext<CriterionPesticide>()
  if (isLoading) {
    return null
  }

  // 国地域の重複無し配列 作成
  const regions = data?.map((d) => d.criterionDataItems.map((c) => c.region)).flat()
  const uniqueRegions = Array.from(new Map(regions?.map((r) => [r.id, r])).values())

  const sort = { field: 'pesticidesNameJapanese', order: 'ASC' } // HACK: 仮置き(sortがないとエラーになるため)

  return (
    <Datagrid bulkActionButtons={false} data={data} sort={sort} total={total}>
      <TextField label="Pesticides name" sortable={false} source="pesticidesNameEnglish" />
      <TextField label="成分名" sortable={false} source="pesticidesNameJapanese" />
      {uniqueRegions.map((r) => {
        return (
          <CriterionValueField
            key={r.id}
            label={r.regionName}
            regionId={r.id}
            sortable={false}
            source="criterionValue"
          />
        )
      })}
    </Datagrid>
  )
}

// filtering用入力欄
const CriterionValueFilters = [
  <TextInput
    alwaysOn
    key="filtering_keyword"
    label="成分名"
    source="pesticidesNameJapanese_contains"
    sx={{ width: '264px' }}
  />,
]

// カスタム編集ボタン
// HACK: 遷移先をカスタムするためにカスタムした
const CustomEditButton = ({ redirectPath }: { redirectPath: string }) => {
  const redirect = useRedirect()

  return (
    <Button
      onClick={() => {
        redirect(redirectPath)
      }}
    >
      <ContentCreate sx={{ fontSize: '18px', marginRight: '8px' }} />
      編集
    </Button>
  )
}

// 判定基準タブのactions
// 管理者または承認者のみ編集が可能
const CriterionValueActions = ({ isEditable, id }: { isEditable: boolean; id: string | undefined }) => {
  return (
    <TopToolbar>
      {isEditable && <CustomEditButton redirectPath={`/judgement_criteria/${id}/criterion_values`} />}
    </TopToolbar>
  )
}

// カスタム登録ボタン
// HACK: 遷移先をカスタムするためにカスタムした
const CustomCreateButton = ({ redirectPath }: { redirectPath: string }) => {
  const redirect = useRedirect()

  return (
    <Button
      onClick={() => {
        redirect(redirectPath)
      }}
    >
      <AddIcon sx={{ fontSize: '18px', marginRight: '8px' }} />
      基準値を追加
    </Button>
  )
}

// 基準値がない場合のemptyページ
const CriterionValuesEmpty = () => {
  // 管理者と承認者のみ、判定基準・基準値の追加・更新が可能
  const { permissions } = usePermissions()
  const isEditable = ['admin', 'approver'].includes(permissions)
  const { id } = useParams()

  return (
    <Box m={1} textAlign="center">
      {isEditable ? (
        <CustomCreateButton redirectPath={`/judgement_criteria/${id}/criterion_values`} />
      ) : (
        <p>この判定基準に紐づく基準値がありません。</p>
      )}
    </Box>
  )
}

// 判定基準タブ
export const CriterionValueTab = ({ isEditable }: { isEditable: boolean }) => {
  // 基準値一覧データ 取得準備
  const { id } = useParams()

  return (
    // HACK: タイトルにresource名を表示しないようにするために、titleを半角スペースにしている。""だとresource名が表示される。falseだとエラー
    <List
      actions={<CriterionValueActions id={id} isEditable={isEditable} />}
      empty={<CriterionValuesEmpty />}
      filters={CriterionValueFilters}
      resource={`judgement_criteria/${id}/pesticides`}
      sx={overflowListStyle}
      title=" "
    >
      <CriterionValueTabContents />
    </List>
  )
}

// --- 以下、判定基準値 修正画面関係 --- //
const CsvExportLink = ({ text }: { text: string }) => {
  const { id } = useParams()

  // csvファイルダウンロード準備 (ファイル名：日付_時間_hanteikijun.csv)
  const now = new Date()
  const fileDate = format(now, 'yyyyMMdd_HHmmss')
  const fileName = `${fileDate}_hanteikijun`

  const url = `/api/judgement_criteria/${id}/export_csv`

  return (
    <StyledCsvExportLink download={fileName} href={url}>
      {text}
    </StyledCsvExportLink>
  )
}

export const CriterionValueEdit = () => {
  const { id } = useParams()
  const [create] = useCreate()
  const redirect = useRedirect()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  // 判定基準の更新処理
  const { mutate: update } = useMutation<void, Error, File>(
    async (csvFile) => {
      if (!csvFile) {
        return notify('ファイルの形式が不正です。', { type: 'error' })
      }
      const csv = await fileToString(csvFile)
      const { data: pesticideDataItems } = await dataProvider.parseJudgementCriterionCsv({ csv })
      // TODO: createではなく、自作プロパティで行う
      create(
        `judgement_criteria/${id}/all_data_update`,
        {
          data: {
            pesticideDataItems: compressToBase64(JSON.stringify(pesticideDataItems)), // HACK: サイズが大きくなりがちなので圧縮する
          },
        },
        {
          onSuccess: () => {
            notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' })
            redirect(`/judgement_criteria/${id}/show/criterion_values`)
          },
          onError: (error) => {
            const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
            notify(errorMessage, { type: 'error' })
          },
        }
      )
    },
    {
      // CSVのparseでエラーが出たときの処理 (createのエラー処理とは別途必要)
      onError: (error) => {
        const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
        notify(errorMessage, { type: 'error' })
      },
    }
  )

  // 判定基準の追加処理
  const { mutate: append } = useMutation<void, Error, File>(
    async (csvFile) => {
      if (!csvFile) {
        return notify('ファイルの形式が不正です。', { type: 'error' })
      }
      const csv = await fileToString(csvFile)
      const { data: pesticideDataItems } = await dataProvider.parseJudgementCriterionCsv({ csv })
      // TODO: createではなく、自作プロパティで行う
      create(
        `judgement_criteria/${id}/append_data`,
        {
          data: {
            pesticideDataItems: compressToBase64(JSON.stringify(pesticideDataItems)), // HACK: サイズが大きくなりがちなので圧縮する
          },
        },
        {
          onSuccess: () => {
            notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' })
            redirect(`/judgement_criteria/${id}/show/criterion_values`)
          },
          onError: (error) => {
            const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
            notify(errorMessage, { type: 'error' })
          },
        }
      )
    },
    {
      // CSVのparseでエラーが出たときの処理 (createのエラー処理とは別途必要)
      onError: (error) => {
        const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
        notify(errorMessage, { type: 'error' })
      },
    }
  )

  return (
    <Edit actions={false} mutationMode="pessimistic" title={DEFAULT_TITLE + ' > 判定基準の登録・更新'}>
      <SimpleForm toolbar={false}>
        <TypographyCsvUpload variant="h5">判定基準の更新</TypographyCsvUpload>
        <PWithNoMargin>
          エクスポートした判定基準を目的の内容に編集後、アップロードすることで基準値が更新されます。ファイルに含まれない基準値は削除されます。
        </PWithNoMargin>
        <CsvExportLink text="現在の判定基準のエクスポート" />
        <FileInputJudgementEdit
          accept="text/csv"
          label=" "
          onChange={(e) => {
            update(e)
          }}
          placeholder={<DropZonePlaceHolder />}
          source="attachments"
        />
        <TypographyCsvUpload variant="h5">判定基準の追加</TypographyCsvUpload>
        <PWithNoMargin>
          国名、成分名、基準値を含むcsvファイルをアップロードすることで、新しい国に対する判定基準を追加します。ファイルに含まれない基準値は維持されます。
        </PWithNoMargin>
        <PWithNoMargin>
          ファイルは次のような形式で作成してください。国は基準取り込み設定で事前に設定しておく必要があります。
        </PWithNoMargin>
        <ImgCsv alt="CSVファイルの形式サンプル" src={CsvFormatSample} />
        <FileInputJudgementEdit
          accept="text/csv"
          label=" "
          onChange={(e) => {
            append(e)
          }}
          placeholder={<DropZonePlaceHolder />}
          source="attachments"
        />
      </SimpleForm>
    </Edit>
  )
}

// -- 以下、csvアップで判断基準 新規追加関係 -- //
const DropZonePlaceHolder = () => {
  return (
    <div>
      <PWithNoMargin>ここにファイルをドロップ</PWithNoMargin>
      <PPlaceHolder>またはクリックしてファイルを選択</PPlaceHolder>
    </div>
  )
}

// csvで判定基準新規登録時の保存ボタン
// サーバー側で解析したcsv(JSONにしたもの)と、フォームの入力値をまとめてPOSTリクエストを投げる
const JudgementCriterionCreateSaveButton = ({ parsedCsv }: { parsedCsv: ParsedCsvPesticideDataItem[] | undefined }) => {
  const dataProvider = useDataProvider()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { getValues, handleSubmit } = useFormContext() // HACK: useFormContextを使うため、登録処理はformとは別コンポーネントにした
  const redirect = useRedirect()
  const notify = useNotify()

  // 保存ボタン押下時の挙動カスタム (解析済みcsvデータとフォーム入力値をまとめて送信)
  const { mutate: onSave } = useMutation<void, Error>(
    async () => {
      setIsSubmitting(true)
      const { attachments: _attachments, ...data } = getValues()

      // parseされたcsvのデータとフォームの入力値をまとめる。また、サーバー側が期待する型に変換する。
      const combinedData = {
        pesticideDataItems: typeof parsedCsv !== 'undefined' ? [...parsedCsv] : [],
        judgementCriterion: {
          ...data,
          mediumThreshold: data.mediumThreshold.toString(),
          mediumAppliedRegionIds:
            typeof data.mediumAppliedRegionIds === 'number'
              ? [data.mediumAppliedRegionIds]
              : data.mediumAppliedRegionIds,
          undetectableThreshold: data.undetectableThreshold.toString(),
          undetectableNotAppliedRegionIds:
            typeof data.undetectableNotAppliedRegionIds === 'number'
              ? [data.undetectableNotAppliedRegionIds]
              : data.undetectableNotAppliedRegionIds,
        },
      }
      await dataProvider.judgementCriterionCreateMany(combinedData)
      setIsSubmitting(false)
    },
    {
      onSuccess: () => {
        notify('ra.notification.created', { type: 'info' })
        redirect('/judgement_criteria')
      },
      onError: (error) => {
        const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
        notify(errorMessage, { type: 'error' })
      },
    }
  )

  return (
    <Toolbar>
      <SaveButton disabled={isSubmitting} label="この内容で登録する" onClick={handleSubmit(() => onSave())} />
    </Toolbar>
  )
}

// 判定基準追加 コンポーネント
// csvの解析情報があるかで、表示切替
export const JudgementCriterionCreate = () => {
  const notify = useNotify()
  const [parsedCsv, setParsedCsv] = useState<ParsedCsvPesticideDataItem[] | undefined>(undefined)

  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  // csvファイルをアップし、サーバー側で解析されたJSONを取得する準備
  const dataProvider = useDataProvider()
  const { mutate } = useMutation<void, Error, File>(
    async (csvFile) => {
      if (!csvFile) {
        return notify('ファイルの形式が不正です。', { type: 'error' })
      }
      const csv = await fileToString(csvFile)
      const { data: pesticideDataItems } = await dataProvider.parseJudgementCriterionCsv({ csv })
      setParsedCsv(pesticideDataItems)
    },
    {
      onError: onError,
    }
  )

  // 国地域関連の選択肢作成用、情報取得
  const { data, isLoading } = useGetList<Region>('regions', FETCH_PARAMS_FOR_THOUSAND_ITEMS)
  if (isLoading) {
    return null
  }

  const japanData = data?.find((r) => r.regionName === '日本')

  return (
    <Create mutationOptions={{ onError }} title={DEFAULT_TITLE + ' > 判定基準の登録'}>
      {typeof parsedCsv === 'undefined' ? (
        // csvファイルアップロード画面
        <SimpleForm toolbar={false}>
          <TypographyCsvUpload variant="h5">判定基準の追加</TypographyCsvUpload>
          <PWithNoMargin>
            国名、成分名、基準値を含むcsvファイルをアップロードすることで、判定基準を追加します。
          </PWithNoMargin>
          <PWithNoMargin>ファイルは次のような形式で作成してください。</PWithNoMargin>
          <PWithNoMargin>取り込み対象とする国は基準取り込み設定で事前に設定しておく必要があります。</PWithNoMargin>
          <ImgCsv alt="CSVファイルの形式サンプル" src={CsvFormatSample} />
          <FileInputJudgementCreate
            accept="text/csv"
            label=" "
            onChange={(e) => {
              mutate(e)
            }}
            placeholder={<DropZonePlaceHolder />}
            source="attachments"
          />
        </SimpleForm>
      ) : (
        // 追加する基準の概要・判定設定 入力フォーム (csvアップ後に表示される)
        <SimpleForm toolbar={<JudgementCriterionCreateSaveButton parsedCsv={parsedCsv} />}>
          <Typography variant="h5">分析基準の概要・判定基準設定</Typography>
          <DivOverviewJudgement>
            <TextInputOverviewJudgement
              label="名前"
              onKeyDown={preventEnterRegist}
              source="name"
              validate={[validationName, validationNameLength]}
            />
            <SpanOverviewJudgement>{nameDescription}</SpanOverviewJudgement>
          </DivOverviewJudgement>
          <DivOverviewJudgement>
            <SelectInputOverviewJudgement
              choices={[
                { id: true, name: 'する' },
                { id: false, name: 'しない' },
              ]}
              defaultValue={true}
              label="標準で適用"
              source="isDefaultApplied"
              validate={validationIsDefaultApplied}
            />
            <SpanOverviewJudgement>{isDefaultAppliedDescription}</SpanOverviewJudgement>
          </DivOverviewJudgement>
          <div>
            <DivOverviewJudgement>
              <NumberInputOverviewJudgement
                defaultValue={0.5}
                label="判定を△とする基準 (倍)"
                onKeyDown={preventEnterRegist}
                source="mediumThreshold"
                validate={validationMediumThreshold}
              />
              <SpanOverviewJudgement>{mediumThresholdDescription}</SpanOverviewJudgement>
            </DivOverviewJudgement>
            <DivOverviewJudgement>
              <SelectArrayInputOverviewJudgement
                choices={data}
                defaultValue={japanData?.id}
                isLoading={isLoading}
                label="上記ルールを適用する国・地域"
                optionText="regionName"
                source="mediumAppliedRegionIds"
              />
              <SpanOverviewJudgement>{mediumAppliedRegionsDescription}</SpanOverviewJudgement>
            </DivOverviewJudgement>
          </div>
          <div>
            <DivOverviewJudgement>
              <NumberInputOverviewJudgement
                defaultValue={1}
                label="不検出と同様に扱う閾値 (ppm)"
                onKeyDown={preventEnterRegist}
                source="undetectableThreshold"
                validate={validationUndetectableThreshold}
              />
              <SpanOverviewJudgement>{undetectableThresholdDescription}</SpanOverviewJudgement>
            </DivOverviewJudgement>
            <DivOverviewJudgement>
              <SelectArrayInputOverviewJudgement
                choices={data}
                defaultValue={japanData?.id}
                isLoading={isLoading}
                label="上記ルールの適用除外"
                optionText="regionName"
                source="undetectableNotAppliedRegionIds"
              />
              <SpanOverviewJudgement>{undetectableNotAppliedRegionsDescription}</SpanOverviewJudgement>
            </DivOverviewJudgement>
          </div>
        </SimpleForm>
      )}
    </Create>
  )
}
