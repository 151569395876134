import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import {
  Create,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  List,
  required,
  SimpleForm,
  SaveButton,
  TextInput,
  TextField,
  Toolbar,
  useNotify,
  usePermissions,
  WrapperField,
} from 'react-admin'
import { useParams, useLocation } from 'react-router-dom'

import { resolveErrorMessage } from '../utils/resolveErrorMessage'

// 共通で使う文字列などを定数化
const DEFAULT_TITLE = '基準取り込み設定'
const BASE_URL = 'criterion'
const IMPORT_RULE_RESOURCE = 'ignore_criterion_keywords'
const IMPORT_RULE_TAB_NAME = 'import_rule'

// 保存ボタンのラベル、削除ボタンの確認ダイアログをカスタム
// TODO: 削除確認ダイアログの選択肢を「確認」→「削除」にする
type customToolbar = { showDelete: boolean; target?: string; redirect?: string }
const CustomToolbar = (props: customToolbar) => {
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '削除できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  return (
    <Toolbar>
      <SaveButton label="この内容で保存する" />
      {props.showDelete && (
        <DeleteWithConfirmButton
          confirmContent="よろしいですか？"
          confirmTitle={`${props.target}を削除します。`}
          mutationOptions={{ onError }}
          redirect={props.redirect}
          sx={{ marginLeft: 'auto' }}
        />
      )}
    </Toolbar>
  )
}

const LeftMarginedSpan = styled('span')({
  marginLeft: '16px',
})

// -- 以下、取り込みルールタブ -- //
// DBに「基準値なしとして扱う文字列」のデータが1つもないとき
const ImportRuleEmpty = ({ isEditable }: { isEditable: boolean }) => (
  <Box m={1} textAlign="center">
    {isEditable ? <CreateButton label="文字列を追加" /> : <span>基準値なしとして扱う文字列がありません。</span>}
  </Box>
)

export const ImportRuleTab = () => {
  // 管理者または承認者のみ編集・追加・削除が可能
  const { permissions } = usePermissions()
  const isEditable = ['admin', 'approver'].includes(permissions)

  // HACK: 表示されていないときはList非表示にすることで余計な読み込みを防ぐ。これはページネーションを動作させるのに必要。
  const location = useLocation()
  const isImportRuleTabActive = location.pathname.includes('/criterion/import_rule')

  return (
    <>
      <p>判断基準をファイルから取り込む際の、基準文字列から実際の基準値への変換ルールを設定します。</p>
      <Typography variant="h6">基本ルール</Typography>
      <p>
        数値を含む文字列は、先頭から連続する数値部分が基準値(ppm)として設定されます。
        <br />
        <LeftMarginedSpan>例：「0.02 ※1」の場合… 「0.02 ppm」を基準値として設定</LeftMarginedSpan>
      </p>
      <p>
        先頭に数値を含まない文字列で、以下で設定する文字列以外の文字列（空白の場合を含む）は、一律「不検出」として扱われます
      </p>
      <Typography variant="h6">基準値なしとして扱う文字列</Typography>
      {/* // HACK: タイトルにresource名を表示しないようにするために、titleを半角スペースにしている。""だとresource名が表示される。falseだとエラー */}
      {isImportRuleTabActive ? (
        <List
          actions={false}
          empty={<ImportRuleEmpty isEditable={isEditable} />}
          resource={IMPORT_RULE_RESOURCE}
          sx={{ width: 1 }}
          title=" "
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              '& .column-keyword': { width: '80%' },
            }}
          >
            <TextField label="文字列" sortable={false} source="keyword" />
            {isEditable && (
              <WrapperField label={<CreateButton label="文字列を追加" />}>
                <EditButton label="" />
                <DeleteWithConfirmButton
                  confirmContent="よろしいですか？"
                  confirmTitle="基準値なしとして扱う文字列を削除します。"
                  label=""
                  redirect={() => `${BASE_URL}/${IMPORT_RULE_TAB_NAME}`}
                />
              </WrapperField>
            )}
          </Datagrid>
        </List>
      ) : null}
    </>
  )
}

// -- 以下、取り込みルール編集画面 -- //
// TODO: 余裕があればモーダルウインドウにする (別画面でも仕様としては問題ない)
export const ImportRuleEdit = () => {
  const { id } = useParams()

  // エラーメッセージ通知準備
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  return (
    <Edit
      id={id}
      mutationMode="pessimistic"
      mutationOptions={{ onError }}
      redirect={() => `${BASE_URL}/${IMPORT_RULE_TAB_NAME}`}
      resource={IMPORT_RULE_RESOURCE}
      title={DEFAULT_TITLE + ' > 基準値なしとして扱う文字列の設定'}
    >
      <SimpleForm
        toolbar={
          <CustomToolbar
            redirect={`/${BASE_URL}/${IMPORT_RULE_TAB_NAME}`}
            showDelete={true}
            target="基準値なしとして扱う文字列"
          />
        }
      >
        <TextInput label="基準値なしとして扱う文字列" source="keyword" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}

// -- 以下、取り込みルール登録画面 -- //
// バリデーションの設定
const validationKeyword = required('基準値なしとして扱う文字列は空欄にできません')

// TODO: 余裕があればモーダルウインドウにする (別画面でも仕様としては問題ない)
export const ImportRuleCreate = () => {
  // エラーメッセージ通知準備
  const notify = useNotify()
  const onError = (error: unknown) => {
    const errorMessage = resolveErrorMessage(error, '登録できませんでした。')
    notify(errorMessage, { type: 'error' })
  }

  return (
    <Create
      mutationOptions={{ onError }}
      redirect={() => `${BASE_URL}/${IMPORT_RULE_TAB_NAME}`}
      resource={IMPORT_RULE_RESOURCE}
      title={DEFAULT_TITLE + ' > 基準値なしとして扱う文字列の追加'}
    >
      <SimpleForm toolbar={<CustomToolbar showDelete={false} />}>
        <TextInput label="基準値なしとして扱う文字列" source="keyword" validate={validationKeyword} />
      </SimpleForm>
    </Create>
  )
}
