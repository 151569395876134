"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountGetOneResponse = void 0;
const common_1 = require("../common");
class Account {
    id;
    loginId;
    name;
    role;
    state;
}
class AccountGetOneResponse extends common_1.ResponseBase {
}
exports.AccountGetOneResponse = AccountGetOneResponse;
